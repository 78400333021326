$nextPageLinkBackgroundColor: #000c26;
$nextPageLinkColor: #fff;
@import '../../core/scss/next-page';

@media (min-width: 768px) {
  .link__nextPage-container {
    padding-bottom: 10px;
  }
}

.link__nextPage {
  font-family: Roboto;
}
