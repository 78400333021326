.teaser-bundle {
  background: var(--c-light);
  margin-bottom: var(--space-2);

  .bundle-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--space-4);
    background: var(--c-light);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      border-bottom: var(--divider-line-bundle);
      bottom: 0;
      width: calc(100vw - 2 * var(--space-4));
    }

    .title {
      @include title-list();
      display: flex;
      align-items: center;

      &:only-child {
        width: 100%;
        overflow-wrap: break-word;
      }

      & .icon {
        margin-left: var(--space-1);
        margin-right: var(--space-1);
      }
    }

    .cta {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-left: 12px;
      @include label-2(var(--c-neutral-06));
      & .arrow-right {
        fill: var(--c-primary-03);
        margin-left: var(--space-2);
      }
    }
  }

  .bundle-description {
    @include expandable-summary();
  }

  .bundle-kf {
    @include expandable-summary(15px, 21px, $fontPublico, $fontPublicoBold);
    h1 {
      display: none;
    }
  }

  @include teaser-series-top('.teaser', '.series-header');

  .teaser.medium {
    margin: 0;
    padding-top: var(--space-6);
    padding-bottom: 0;
  }

  &.opinion {
    article:first-of-type .illustration-container {
      border-bottom: none;
    }
  }

  .teaser.bundle-secondary {
    margin: 0;
    padding-bottom: 0;

    & .title {
      margin-bottom: var(--space-2);
      font-weight: 500;
    }

    .author-name {
      color: var(--c-neutral-06);
      margin-bottom: var(--space-1);
    }

    & .container {
      border-top: var(--divider-line);
      padding-top: var(--space-4);
    }

    & .teaser-image-wrapper {
      margin-bottom: 0;
    }

    & [class^='author-image-wrapper'] {
      display: flex;
      align-items: center;
      margin-left: var(--space-2);
      margin-bottom: var(--space-2);
      flex-shrink: 0;

      &::after {
        height: 56px;
        top: auto;
      }
    }

    &.opinion .title {
      margin-bottom: var(--space-1);
      font-style: italic;
    }

    &.illustration .author-image {
      width: 94px;
      height: 94px;
      mix-blend-mode: normal;
    }
  }

  article:last-child.bundle-secondary {
    padding-bottom: var(--space-4);
  }

  .byline {
    padding-bottom: 0;
    border-bottom: none;
    width: auto;
  }
  .teaser-section-title {
    @include label-2(var(--c-dark), 600);
    .section-icon {
      margin-left: 2px;
      &::before {
        top: 1px;
      }
    }
  }
  .teaser.bundle-main {
    padding-bottom: 0;
    &.with-kf {
      .title {
        margin-bottom: var(--space-2);
      }
    }
  }

  .bundle-kf {
    margin: 0;
    border-bottom: none;
    padding: 0 0 var(--space-8) 0;
    li::before {
      content: none;
    }
  }

  .bundle-bottom-link-wrapper {
    padding: var(--space-4);
  }
  .bundle-bottom-link {
    @include label-1($weight: 600);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: var(--divider-line);
    padding: var(--space-4) 0 var(--space-2) 0;

    .arrow {
      border-radius: 50%;
      background-color: var(--c-primary-01);
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .arrow-right {
      fill: var(--c-light);
    }
  }

  &.inverted .bundle-bottom-link {
    color: var(--c-light);
    border-color: var(--c-tint-30);
  }
}

.teaser-bundle.breaking {
  .bundle-title {
    &:not(.special) {
      .title {
        padding-left: var(--space-4);
        position: relative;
      }

      .title::before {
        @include redCircle(8px, 0, var(--c-breaking-light));
      }

      .title::after {
        @include beeper(0);
      }
    }

    & .cta .arrow-right {
      fill: var(--c-dark);
    }
  }

  .bundle-description,
  .bundle-kf {
    & ul li::before {
      background-color: var(--c-breaking-light);
    }
    & summary {
      & li::after,
      & p::after {
        color: var(--c-breaking-light);
      }
    }
  }
}

.teaser-bundle.inverted {
  background: var(--c-dark);

  .bundle-title {
    background: var(--c-dark);
    &::after {
      border-bottom: 1px solid var(--c-tint-30);
    }
    .title {
      color: var(--c-light);
    }
    .cta {
      @include label-2(var(--c-neutral-05));
      & .arrow-right {
        fill: var(--c-light);
      }
    }
  }
  .teaser-section-title {
    color: var(--c-light);
    .section {
      color: var(--c-light);
    }
  }

  .bundle-description,
  .bundle-kf {
    color: var(--c-neutral-03);
    border-bottom: 1px solid var(--c-tint-30);

    & ul li::before {
      background-color: var(--c-neutral-03);
    }
    & summary {
      & li::after,
      & p::after {
        color: var(--c-semantic-info-tint-3);
      }
    }
  }

  .teaser.bundle-secondary {
    & .container {
      border-top: 1px solid var(--c-tint-30);
    }

    & .title {
      color: var(--c-light);
    }

    & .author-name {
      color: var(--c-neutral-05);
    }
  }
}

.teaser-bundle.breaking.inverted {
  .bundle-title {
    .title::before {
      @include redCircle(8px, 0, var(--c-breaking-dark));
    }

    & .cta .arrow-right {
      fill: var(--c-light);
    }
  }

  .bundle-description,
  .bundle-kf {
    & ul li::before {
      background-color: var(--c-breaking-dark);
    }
    & summary {
      & li::after,
      & p::after {
        color: var(--c-breaking-dark);
      }
    }
  }
}

.teaser-bundle.highlighted {
  .bundle-title .cta {
    font-weight: 600;
  }
  &.inverted .bundle-title .cta {
    color: var(--c-light);
  }
}

.teaser-bundle.feature {
  .bundle-title {
    justify-content: center;
    .title {
      @include title-feature();
    }
  }
  .teaser.bundle-main.feature .teaser-image-wrapper {
    margin-bottom: var(--space-2);
  }
}

.teaser-bundle.opinion .bundle-main.opinion {
  &.with-byline,
  &.with-byline-image {
    .byline {
      margin-bottom: var(--space-4);
    }
  }
  .title {
    font-style: italic;
  }
}
