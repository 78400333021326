a.widget-link {
  display: none;
}

.widget-container {
  color: $colorApDark;
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  border-bottom: var(--divider-line);
  padding: 0;
  margin-bottom: var(--space-6);

  a {
    &,
    &:hover,
    &:active {
      text-decoration: none;
      color: inherit;
    }
  }

  .widget-header {
    font-family: $fontProdukt;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    position: relative;

    .title {
      font-size: 18px;
      font-family: $fontProdukt;
      font-weight: 700;

      .icon-ap-logo::before {
        @include a-logo-widget();
      }
    }

    .description {
      margin-left: 2 * $gridBase;
      font-size: 15px;
      font-family: $fontRobotoLight;
      color: $colorApDark;
    }
  }

  a.widget-more-link {
    text-decoration: none;
    display: block;
    font-size: 15px;
    font-family: Roboto-Light, Arial, Helvetica, sans-serif;
    color: $colorApDark;
    position: absolute;
    right: 16px;

    .arrow-right {
      display: none;
    }

    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 3px;
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 2px;
      margin-left: 8px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
}
