.teaser-bundle.desktop {
  .bundle-title {
    padding: 21px 0 9px 0;
    &::after {
      content: none;
    }
  }
  .bundle-description {
    margin: 0;
    flex-direction: row;
    padding-top: 0;

    & h1,
    p,
    li {
      margin: 0;
    }

    & p:only-child {
      max-width: 725px;
    }

    & ul {
      display: flex;
      width: 100%;
    }

    & h1 {
      flex-basis: 210px;
      min-width: 210px;
      font-weight: bold;
    }

    & ul > li,
    > p {
      flex-basis: calc(100% / var(--max-items));
    }

    & h1 + ul > li,
    h1 + p,
    ul > li:not(:first-of-type),
    > p:not(:first-of-type) {
      padding-left: var(--space-4);
    }
    ul > li:not(:last-of-type),
    > p:not(:last-of-type) {
      padding-right: var(--space-4);
    }
  }

  &.feature .bundle-title {
    justify-content: left;
  }

  &.inverted {
    margin: var(--space-2) -14px var(--space-2) -12px;
    padding: 0 var(--space-3);
    .bundle-title {
      border-bottom: 1px solid var(--c-tint-30);
    }
    .bundle-description {
      & h1 + ul > li,
      h1 + p,
      ul > li:not(:first-of-type),
      > p:not(:first-of-type) {
        border-color: var(--c-tint-30);
      }
    }
  }
}

.teaser-bundle.desktop + .teaser-bundle.desktop {
  .bundle-title {
    border-bottom: var(--divider-line);
    padding-top: var(--space-4);
  }
  &.inverted .bundle-title {
    border-color: var(--c-tint-30);
  }
  .bundle-description {
    padding-top: var(--space-4);
  }
}
.bundle-title-link + .bundle-grid {
  border-top: var(--divider-line);
}
