.ap-footer-wrapper {
  position: relative;
  --gridBase: 8px;
  background: var(--c-dark, #000724);
  width: 100%;
  font: 400 16px/18px Graphik;
  box-sizing: border-box;
  z-index: 100;

  .ap-footer {
    display: flex;
    flex-direction: column;
    color: var(--c-light, white);

    a {
      color: var(--c-light, white);
    }

    #data-controller-permanent {
      position: unset;
      width: 100%;
    }
    .container {
      display: flex;
      gap: 88px;
      justify-content: center;
      width: 100%;
      margin: calc(var(--gridBase) * 8) 0px;
    }

    .links,
    .info {
      height: 100%;

      ul {
        width: 100%;
        list-style: none;
        padding: 0px;
        margin: 0px;
      }

      li {
        padding-bottom: 12px;

        &:last-of-type {
          padding: 0px;
        }
      }
    }

    .links {
      display: flex;
      flex-direction: column;
      gap: calc(var(--gridBase) * 4);
      min-width: 202px;

      .telephone {
        font-weight: bold;
      }

      .logos {
        display: flex;
        margin-top: var(--gridBase);
        gap: calc(var(--gridBase) * 4);

        ul li a {
          text-decoration: none;
        }

        .ap-footer-logo-circular {
          background-image: url('https://cdn.aftenposten.no/ap/images/footer/circular-ap-logo.png');
          background-repeat: no-repeat;
          width: 64px;
          height: 64px;
          background-size: contain;
        }
        .ansvars-merket-footer {
          display: block;
          width: 64px;
          height: 64px;
          border: none;
          background-image: url('https://cdn.aftenposten.no/ap/images/footer/ansvarsmerket.svg');
        }
      }
    }

    .info {
      height: auto;
      display: flex;
      flex-direction: column;
      gap: calc(var(--gridBase) * 5);
      max-width: 693px;
      padding-right: 0;
      li {
        .name {
          font-weight: 600;
        }
      }

      a {
        text-decoration: underline;

        &:visited,
        &:link {
          color: white;
        }

        &:hover {
          color: #0059d2;
        }
      }

      .contact-info {
        a {
          font-weight: 500;
        }
      }
    }

    @media screen and (max-width: 980px) {
      .container {
        flex-direction: column;
        font-size: 14px;
        gap: calc(var(--gridBase) * 3);
        margin: calc(var(--gridBase) * 8) calc(var(--gridBase) * 2);
        width: auto;
      }

      .links,
      .info {
        gap: calc(var(--gridBase) * 3);

        li {
          padding-bottom: var(--gridBase);

          &:last-of-type {
            padding: 0px;
          }
        }

        .logos {
          margin: 0px;
        }
      }

      .info .disclaimer {
        margin: 0px;
        font-size: 16px;
      }
    }
  }
}
