.sticky-feedback-wrapper {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001; // higher than ads cog wheel button (1000)

  .sticky-feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: var(--space-2);
    box-shadow: 0px -7px 9px rgba(0, 0, 0, 0.25);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    font-family: Graphik;
    background: #fff;
    padding: var(--space-4) 0;
    text-align: center;

    &__heading {
      font-weight: 600;
    }

    &__paragraph {
      margin: 0 0 8px 0;
    }

    &__close-btn {
      position: absolute;
      top: 0;
      right: 0;
      border: 0;
      background: transparent;
      margin: 10px;
      cursor: pointer;
    }

    &__close-btn-icon {
      width: 25px;

      // for fixing frontpage gray background bug which exists at the time of deployment
      box-shadow: none;
      background-color: transparent;
    }

    &__buttons-wrapper {
      display: flex;
      gap: 20px;
      margin-top: 12px;
    }

    &__response-btn {
      background-color: var(--c-primary-04);
      border: 0;
      color: #fff;
      border-radius: 20px;
      padding: 11px 16px;
      font-size: 13px;
      font-weight: 600;
      width: 135px;
      cursor: pointer;
    }

    &__link {
      text-decoration: underline;
    }

    &__link:a {
      color: #0000ee;
    }

    &__link:visited {
      color: #551a8b;
    }
  }

  @media screen and (max-width: 481px) {
    .sticky-feedback {
      height: 150px;

      span {
        display: block;
      }
    }
  }
}
