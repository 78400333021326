@mixin padded-separator($color) {
  content: '';
  position: absolute;
  width: calc(100% - var(--space-8));
  left: var(--space-4);
  height: 1px;
  background-color: $color;
  z-index: 1;
}

@mixin series-header-img(
  $top: 8px,
  $img,
  $left: 8px,
  $position: absolute,
  $width: 100px,
  $height: 30px
) {
  content: '';
  background-image: url('https://cdn.aftenposten.no/ap/images/series-headers/#{$img}.svg');
  position: $position;
  width: $width;
  height: $height;
  top: $top;
  left: $left;
  z-index: 1;
}

@mixin teaser-series-top($container: '.teaser', $class: '.series-header') {
  #{$container}.series- {
    &location,
    &reveal,
    &live,
    &document {
      #{$class} {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: var(--space-2);
        @include label-2(var(--c-dark), 600);
        &::before {
          @include series-header-img(
            0,
            'small-ap-logo',
            0,
            relative,
            21px,
            18px
          );
          margin-right: var(--space-1);
        }
      }
    }

    &reveal #{$class}::after {
      @include series-header-img(0, 'reveals/regular', 0, relative, 65px, 18px);
    }
    &live #{$class}::after {
      @include series-header-img(
        0,
        'live/direkte-regular',
        0,
        relative,
        59px,
        18px
      );
    }
    &document #{$class}::after {
      @include series-header-img(
        0,
        'documents/regular',
        0,
        relative,
        100px,
        18px
      );
    }
    &location #{$class} .context-label-series {
      background-color: var(--c-neutral-02);
      padding: 1px 8px;
      border-radius: 2px;
    }

    &reveal,
    &live,
    &document {
      #{$class} {
        &__e24::before {
          display: none !important;
        }

        .section-icon__e24 {
          margin-left: 0;
          margin-right: 4px;
        }
      }
    }
  }

  #{$container}.inverted.series- {
    &location,
    &reveal,
    &live,
    &document {
      #{$class}::before {
        @include series-header-img(
          0,
          'small-ap-logo-light',
          0,
          relative,
          21px,
          18px
        );
      }
    }
  }
}
//series-label for feature can be expanded to all series-labels
@mixin series-feature($mobile: true) {
  .feature {
    &.series-reveal,
    &.series-live,
    &.series-document,
    &.series-location {
      .feature-container::before {
        @if $mobile {
          @include series-header-img(
            16px,
            'small-ap-logo',
            16px,
            $width: 21px,
            $height: 18px
          );
        } @else {
          @include series-header-img(8px, 'regular-ap-logo', $width: 95px);
        }
      }
    }

    &.series-reveal {
      .feature-container::after {
        @if $mobile {
          @include series-header-img(
            16px,
            'reveals/regular',
            41px,
            $width: 66px,
            $height: 19px
          );
        } @else {
          @include series-header-img(
            41px,
            'reveals/regular',
            $width: 66px,
            $height: 18px
          );
        }
      }
    }

    &.series-live {
      .feature-container::after {
        @if $mobile {
          @include series-header-img(
            16px,
            'live/direkte-regular',
            41px,
            $width: 59px,
            $height: 18px
          );
        } @else {
          @include series-header-img(
            41px,
            'live/direkte-regular',
            $width: 59px,
            $height: 18px
          );
        }
      }
    }

    &.series-document {
      .feature-container::after {
        @if $mobile {
          @include series-header-img(
            16px,
            'documents/regular',
            41px,
            $height: 19px
          );
        } @else {
          @include series-header-img(41px, 'documents/regular', $height: 19px);
        }
      }
    }

    &.series-location {
      .feature-container {
        .series-header {
          @if $mobile {
            @include label-2(var(--c-dark), 600);
            position: absolute;
            top: 16px;
            left: 41px;
            background-color: var(--c-light);
            border-radius: 2px;
            padding: 0px 8px 2px;
          } @else {
            @include label-2(var(--c-dark), 600);
            position: absolute;
            top: 42px;
            left: 9px;
            background-color: var(--c-light);
            height: 18px;
            border-radius: 2px;
            padding: 1px 8px;
          }
        }
      }
    }
  }
}
