// ALIGMENT
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}

// TYPOGRAPHY
a,
a:hover,
a:visited,
a:focus {
  text-decoration: none;
  color: #000;
}

// IMAGES
@mixin img-cover {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
