$oppsummertWidth: 232px;
$oppsummertImageHeight: 342px;
$oppsummertBottomHeight: 41px;
$oppsummertSpacing: 14px;

#oppsummert {
  position: relative;
  margin: 0;

  a,
  button {
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }

  .feed {
    display: flex;
    overflow: hidden;
    padding: 0;
  }

  .feed-item {
    display: inline-block;
    background-color: var(--c-dark);
    border-radius: var(--image-radius);
    position: relative;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    margin: var(--space-4) $oppsummertSpacing var(--space-4) 0;
    will-change: max-height;
    transition: max-height 0.2s ease-in-out;

    &-label {
      @include label-2(var(--c-light));
      margin-bottom: 2px;
      text-transform: initial;
    }

    &-title {
      @include title-list-item();
      font-size: 18px;
      line-height: 21px;
      color: var(--c-light);
      @include line-clamp(3);
      margin-bottom: var(--space-1);
    }

    .oppsummert-overlay-wrapper {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 50%;
        background: linear-gradient(
          180deg,
          rgba(0, 7, 36, 0) 0%,
          var(--c-dark) 80%,
          var(--c-dark) 100%
        );
        border-radius: 0;
        left: 0;
        right: 0;
        bottom: -1px;
        z-index: 1;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .feed-item-desc-wrapper {
      padding-right: var(--space-4);
    }

    .feed-item-desc {
      padding: 0 var(--space-4) var(--space-2);
      color: var(--c-light);
      flex-direction: column;
      white-space: normal;
      height: 90px;
      z-index: 10;
      position: relative;
      display: inline-flex;
      flex-direction: column-reverse;
    }

    .image-wrapper {
      width: $oppsummertWidth;
      height: $oppsummertImageHeight;
      display: inline-flex;
      flex-direction: column-reverse;
      background-size: cover;
      border-radius: var(--image-radius);
    }
  }

  .oppsummert-divider {
    position: sticky;
    height: 1px;
    width: calc(100% - var(--space-4) * 2);
    margin: 0 auto;
    background-color: var(--c-tint-30);
    z-index: 2;
  }

  .oppsummert-bottom-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: Graphik;
    font-size: 14px;
    line-height: 20px;
    background-color: var(--c-dark);
    padding: 0 var(--space-4) var(--space-3);
    color: var(--c-neutral-02);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    height: $oppsummertBottomHeight;

    .summary {
      margin-bottom: var(--space-4);

      &-button {
        cursor: pointer;
        background: none;
        color: var(--c-blue-30);
        font-family: Graphik;
        font-weight: 500;
        padding: var(--space-3) 0 0;
        border: none;
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        gap: var(--space-1);

        &::after {
          transition: all 0.1s ease;
          content: url('data:image/svg+xml;charset=UTF-8,%3Csvg width="14" height="8" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M7 6.26 1.8.67a.515.515 0 0 0-.766 0 .613.613 0 0 0 0 .824l5.32 5.718a.912.912 0 0 0 .296.213.858.858 0 0 0 .7 0 .912.912 0 0 0 .296-.213l5.32-5.718a.613.613 0 0 0 0-.824.515.515 0 0 0-.765 0L7 6.26Zm-.12.13Zm.24 0Z" fill="%23D8E8FC"%3E%3C/path%3E%3C/svg%3E');
        }
      }

      &-link {
        font-weight: 500;
        display: flex;
        align-items: center;
        color: var(--c-blue-30);
        padding: 1px 10px 3px;
        padding-left: 0;
        transition: all 0.2s ease-out;
        cursor: pointer;

        gap: var(--space-1);
        position: relative;

        &::after {
          height: 14px;
          content: url('data:image/svg+xml,<svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.588 13.246a.583.583 0 0 1 0-.825l4.837-4.838H1.167a.583.583 0 1 1 0-1.166h10.258L6.588 1.579a.583.583 0 0 1 .824-.825l5.834 5.834a.583.583 0 0 1 0 .824l-5.834 5.834a.583.583 0 0 1-.824 0Z" fill="%23D8E8FC"/></svg>');
        }

        &:hover {
          padding-left: 10px;
          border-radius: var(--space-1);
          background-color: #262c45;
        }
      }
    }
  }

  &:not(.widget-oppsummert-expanded) {
    .oppsummert-bottom-wrapper:hover {
      background-color: #262c45;

      .summary-button {
        color: var(--c-blue-30);
      }
    }
  }

  &.widget-oppsummert-expanded {
    .feed-item-desc {
      height: unset;
    }

    .oppsummert-divider {
      display: none;
    }

    .oppsummert-bottom-wrapper {
      height: calc((100% - $oppsummertImageHeight));
    }

    .summary-button::after {
      transform: rotate(180deg);
    }

    .oppsummert-bottom-expanded-content {
      display: flex;
    }
  }

  .scroll-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc((($oppsummertImageHeight + $oppsummertBottomHeight) / 2));
    z-index: 20;
    width: 40px;
    height: 40px;
    border: 1px solid var(--c-neutral-04);
    background-color: var(--c-light);
    border-radius: 50%;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
    }

    &.left {
      left: 8px;

      img {
        transform: rotate(180deg);
      }
    }
    &.right {
      right: 8px;
    }

    &:hover {
      border: 1px solid var(--c-blue-60);
      background-color: #e8f1fa;

      img {
        filter: brightness(0) saturate(100%) invert(9%) sepia(64%)
          saturate(4794%) hue-rotate(217deg) brightness(97%) contrast(122%);
      }
    }

    &:active {
      border: 1px solid var(--c-dark);
      background-color: #d8e8fc;

      img {
        filter: brightness(0) saturate(100%) invert(6%) sepia(33%)
          saturate(3436%) hue-rotate(207deg) brightness(90%) contrast(110%);
      }
    }
  }
}
