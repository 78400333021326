$mediumFullwidthImageSize: 338px;

img {
  position: relative;
}

.teaser-image,
.author-image,
.widget-most-read-widget img {
  background-color: #f1f1f1;
  background-size: cover;
  box-shadow: inset 0 0 170px rgba(0, 12, 38, 0.08);
  color: transparent;
}

.teaser.large .teaser-image-wrapper,
.teaser.medium .teaser-image-wrapper,
.teaser.bundle-main .teaser-image-wrapper,
.teaser.bundle-main.feature .teaser-image-wrapper {
  margin-bottom: var(--space-4);
  width: 100%;
  position: relative;
  aspect-ratio: 3/2;

  .teaser-image {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    border-radius: var(--image-radius);
  }
}

.mobile .teaser.large .teaser-image-wrapper,
.mobile .teaser.small .teaser-image-wrapper {
  aspect-ratio: 1/1;
}

.mobile .teaser.topstripe .teaser-image-wrapper {
  aspect-ratio: auto;
}

.teaser.small .teaser-image-wrapper,
.teaser.bundle-secondary .teaser-image-wrapper {
  display: flex;
  .teaser-image {
    border-radius: var(--image-radius);
    height: 104px;
    width: 104px;
    object-fit: cover;
    object-position: center;
    color: transparent;
  }
}

.teaser.large.magazine {
  height: 100%;
}
.teaser.large.inverted {
  .teaser-image {
    border-radius: var(--image-radius) var(--image-radius) 0 0;
  }
}

.teaser.feature .teaser-image-wrapper {
  position: unset;
  display: flex;
  margin-bottom: 0;
  .teaser-image {
    position: unset;
  }
}

.teaser.bundle-main,
.teaser.kort-forklart.large {
  .teaser-image-wrapper .teaser-image {
    object-fit: cover;
  }
}

.teaser.feature.large .teaser-image-wrapper .teaser-image,
.teaser.feature.bundle-main .teaser-image-wrapper .teaser-image {
  height: 100%;
  width: 100vw;
  object-fit: cover;
}

.teaser.memorial .teaser-image-wrapper .teaser-image {
  height: 94px;
  width: 94px;
  border-radius: 80px;
  filter: grayscale(100%);
}

.teaser {
  .no-script {
    position: absolute;
    z-index: 1;
  }
  .no-script.author-image {
    bottom: 0;
  }
}

.teaser-wrapper.fullwidth.mobile > .teaser.medium,
.teaser-wrapper.double > .teaser.medium,
.teaser.large,
.teaser.top-small-vertical.with-image,
.teaser.top-small-vertical.with-image.breaking {
  &.series-reveal,
  &.series-live,
  &.series-document,
  &.series-location {
    .teaser-image-wrapper::before {
      @include series-header-img(
        16px,
        'small-ap-logo',
        16px,
        $width: 21px,
        $height: 18px
      );
    }
  }

  &.series-reveal {
    .teaser-image-wrapper::after {
      @include series-header-img(
        16px,
        'reveals/regular',
        41px,
        $width: 66px,
        $height: 19px
      );
    }
  }

  &.series-live {
    .teaser-image-wrapper::after {
      @include series-header-img(
        16px,
        'live/direkte-regular',
        41px,
        $width: 59px,
        $height: 18px
      );
    }
  }

  &.series-document {
    .teaser-image-wrapper::after {
      @include series-header-img(
        16px,
        'documents/regular',
        41px,
        $height: 19px
      );
    }
  }
  &.series-location {
    .teaser-image-wrapper {
      .series-header {
        @include label-2(var(--c-dark), 600);
        position: absolute;
        top: 16px;
        left: 41px;
        background-color: var(--c-light);
        border-radius: 2px;
        padding: 1px 8px 1px;
      }
    }
  }
}

.teaser.large .teaser-image-wrapper {
  height: calc(100vw - 32px);

  .teaser-image {
    object-fit: cover;
  }
}
.teaser.large,
.teaser.medium {
  &.video-link {
    .teaser-image-wrapper {
      .video-link-header {
        position: absolute;
        top: 8px;
        left: 8px;
        display: flex;
        align-items: center;
        opacity: 0.8;
        background-color: var(--c-dark);
        padding: 0 12px;
        border-radius: 2px;
        height: 24px;
        gap: 4px;
        @include label-1(var(--c-light));

        &::before {
          content: '';
          display: inline-block;
          width: 10px;
          height: 12px;
          background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/audio/play-rounded.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

// desktop

@media (min-width: 765px) {
  .teaser.small .teaser-image-wrapper,
  .teaser.horizontal .teaser-image-wrapper,
  .teaser.bundle-secondary .teaser-image-wrapper {
    margin-bottom: 0;
    display: flex;
    flex-shrink: 0;
    .teaser-image {
      width: 209px;
      height: 139px;
      color: transparent;
      object-fit: cover;
      object-position: center;
    }
  }

  .teaser.large.magazine .teaser-image-wrapper {
    .image-padding {
      padding-bottom: 66.67% !important;
    }
  }

  .one-plus-three .teaser.large.history-magazine .teaser-image-wrapper {
    height: 311px;

    .teaser-image {
      object-fit: cover;
      position: inherit;
    }
  }

  .teaser.medium .teaser-image-wrapper {
    .teaser-image {
      object-fit: cover;
    }
  }

  .teaser.memorial.small .teaser-image-wrapper {
    text-align: center;
  }

  .teaser.memorial.medium .teaser-image-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 36%;
  }

  .teaser.memorial .teaser-image-wrapper .teaser-image {
    width: 135px;
    height: 135px;
    border-radius: 135px;
    filter: grayscale(100%);
  }

  .full-width-top .teaser.small .teaser-image-wrapper {
    margin: 0 50px 0 0;
  }

  .full-width .teaser.large.inverted .teaser-image-wrapper {
    margin-bottom: 0;
    width: 467px;
    height: 311px;
    flex-shrink: 0;
  }

  .full-width-top .teaser.small.horizontal {
    .teaser-image-wrapper {
      margin-left: 0;
      margin-right: 49px;
    }
  }

  .feature-on-top .teaser.large .teaser-image-wrapper {
    height: 311px;
    width: 467px;
  }

  .feature {
    .row .memorial {
      .teaser-image-wrapper {
        margin-bottom: 0;
      }
      .teaser-image {
        position: inherit;
      }
    }
  }

  .block.x-large {
    .teaser.large.magazine .teaser-image-wrapper {
      .teaser-image {
        width: 100%;
        height: 100%;
      }
    }
  }

  .teaser.medium.kort-forklart {
    &.series-reveal,
    &.series-live,
    &.series-document,
    &.series-location {
      .teaser-image-wrapper::before {
        @include series-header-img(auto, 'regular-ap-logo', $width: 95px);
        bottom: 273px;
      }
      .teaser-image-wrapper::after {
        top: auto;
        bottom: 250px;
      }
    }
    &.series-location {
      .teaser-image-wrapper {
        .series-header {
          top: auto;
          bottom: 250px;
        }
      }
    }
  }
  .teaser.large .teaser-image-wrapper {
    height: auto;
  }
  .fullwidth .teaser.medium {
    .teaser-image-wrapper {
      max-width: $mediumFullwidthImageSize;
      margin-bottom: 0;
      flex-shrink: 0;
    }
  }
  .fullwidth .teaser.small.inverted {
    .teaser-image {
      border-radius: 0 var(--image-radius) var(--image-radius) 0;
    }
  }

  .fullwidth {
    .teaser.magazine.medium {
      &.series-reveal,
      &.series-live,
      &.series-document,
      &.series-location {
        .teaser-image-wrapper::before,
        .teaser-image-wrapper::after {
          content: none;
        }
        .series-header {
          display: none;
        }
      }
    }
  }

  .teaser.large {
    &:not(.video-link) {
      &.series-reveal,
      &.series-live,
      &.series-document,
      &.series-location {
        .teaser-image-wrapper::before {
          @include series-header-img(8px, 'regular-ap-logo', $width: 95px);
        }
      }

      &.series-reveal {
        .teaser-image-wrapper::after {
          @include series-header-img(
            41px,
            'reveals/regular',
            $width: 66px,
            $height: 18px
          );
        }
      }

      &.series-live {
        .teaser-image-wrapper::after {
          @include series-header-img(
            41px,
            'live/direkte-regular',
            $width: 59px,
            $height: 18px
          );
        }
      }

      &.series-document {
        .teaser-image-wrapper::after {
          @include series-header-img(41px, 'documents/regular', $height: 19px);
        }
      }
      &.series-location {
        .teaser-image-wrapper {
          .series-header {
            @include label-2(var(--c-dark), 600);
            position: absolute;
            top: 42px;
            left: 9px;
            background-color: var(--c-light);
            height: 18px;
            border-radius: 2px;
            padding: 1px 8px;
          }
        }
      }
    }
  }
}
