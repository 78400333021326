#bottom-bar {
  position: sticky;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 1001; // higher than ads cog wheel button (1000)
  border-top: var(--divider-line);

  &.hidden,
  &.dismissed {
    display: none;
  }

  &.shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
