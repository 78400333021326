.react-widget-wrapper.most-read-widget.vertical {
  display: inline-block;
  width: 100%;
}

.react-widget-wrapper.most-read-widget {
  .header-title-gray {
    font-family: $fontGraphik;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #767676;
  }

  section.vertical {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 32px;

    article a img {
      aspect-ratio: 1 / 0.67;
    }
  }

  section.horizontal {
    padding-top: 16px;
    padding-bottom: 16px;

    .feed {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 24px;
    }

    @media screen and (max-width: 628px) {
      .feed {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        padding: 0 16px;
        gap: 16px;
      }
    }
  }

  article a {
    display: flex;
    flex-direction: column;
    gap: var(--space-2);
    &:focus-visible {
      outline: auto;
      outline-color: blue;
    }
    img {
      aspect-ratio: 1 / 1;
      width: 100%;
      border-radius: 4px;
      object-fit: cover;
      box-shadow: inset 0 0 170px rgba(0, 12, 38, 0.08);
      color: transparent;
    }

    .rank {
      font-family: Graphik, Arial, Helvetica, sans-serif;
      color: #00259d;
      letter-spacing: 0;
      font-size: 15px;
      line-height: 16px;
      font-weight: 600;
    }
    .rank::before {
      content: '0';
    }

    .title {
      font-family: $fontGraphik;
      font-size: 15px;
      line-height: 19px;
      letter-spacing: -0.3px;
      font-weight: 600;
    }
  }
}
