.oslo-personalized-widget {
  @include label-1();
  padding: 0 var(--space-4) var(--space-4);

  .react-widget-wrapper__header {
    margin-inline: 0;

    .open-button {
      background-color: transparent;
      border: none;

      img {
        filter: brightness(0) saturate(100%) invert(7%) sepia(27%)
          saturate(3628%) hue-rotate(205deg) brightness(85%) contrast(111%);
      }
    }
  }

  .oslo-widget-descriptor {
    display: flex;
    color: var(--c-neutral-07);
    margin-bottom: var(--space-4);

    &::before {
      display: inline-block;
      content: '';
      background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/info.svg');
      background-repeat: no-repeat;
      background-size: 16px 16px;
      width: 16px;
      height: 16px;
      margin-right: var(--space-1);
      filter: brightness(0) saturate(100%) invert(42%) sepia(4%) saturate(11%)
        hue-rotate(323deg) brightness(88%) contrast(83%);
    }
  }

  .choice-container {
    display: flex;
    flex-direction: column;
    gap: var(--space-4);

    .choice-item {
      display: flex;
      background-color: transparent;
      align-items: center;
      justify-content: space-between;
      gap: var(--space-4);
      padding: var(--space-4);
      font-size: 15px;
      font-weight: 600;
      line-height: 22px;
      border: 1px solid var(--c-neutral-02);
      border-radius: 4px;

      .text-container {
        text-align: left;
        padding-right: var(--space-3);

        h3 {
          font-size: 15px;
          color: var(--c-dark);
        }

        p {
          color: var(--c-neutral-09);
          margin: 0;
          font-weight: 400;
        }
      }

      svg {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
      }

      &.selected {
        background-color: var(--c-elements-backgrounds-blue);
        border: 1px solid var(--c-dark);
      }
    }

    .save-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      padding: 12px 16px;
      gap: 8px;
      font-weight: 500;

      width: 118px;
      height: 40px;

      background: #00259d;
      color: white;
      border-radius: 30px;

      flex: none;
      flex-grow: 0;
    }
  }

  &.top {
    .choice-container {
      padding-top: var(--space-4);
    }
  }
  .confirmation-screen {
    .confirmation-content {
      .confirmation-illustration {
        margin-bottom: 20px;
      }

      h2 {
        margin-bottom: 10px;
      }

      .confirmation-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        padding: 8px 0;
        border-top: 1px solid #0007241a;
        border-bottom: 1px solid #0007241a;

        .change-button,
        .close-button {
          color: var(--c-blue-50);
          padding: 10px;
          margin: 0;
          border: none;
          font-weight: 500;
          cursor: pointer;
          white-space: nowrap;
        }

        .change-button {
          background-color: transparent;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0px;
          gap: 4px;

          height: 24px;

          border-radius: 30px;

          flex: none;
          order: 0;
          flex-grow: 0;

          &::before {
            display: inline-block;
            content: '';
            background-image: url('https://cdn.aftenposten.no/ap/images/active-personalization/edit.svg');
            background-repeat: no-repeat;
            background-size: 16px 16px;
            width: 16px;
            height: 16px;
            margin-right: var(--space-1);
          }
        }

        .close-button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 16px;
          gap: 8px;
          font-size: 14px;

          height: 40px;

          border: 1px solid currentColor;
          border-radius: 30px;
          background: transparent;

          flex: none;
          order: 1;
          flex-grow: 0;

          &::after {
            display: inline-block;
            content: '';
            background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/close.svg');
            background-repeat: no-repeat;
            background-size: 14px 14px;
            width: 14px;
            height: 14px;
            margin-left: var(--space-1);
            filter: brightness(0) saturate(100%) invert(12%) sepia(86%)
              saturate(4521%) hue-rotate(224deg) brightness(81%) contrast(113%);
          }
        }
      }
    }
  }
}
