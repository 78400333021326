@media (min-width: 765px) {
  .kort-forklart {
    &.large {
      max-width: initial;
      border-top: var(--divider-line);
      padding-top: var(--space-6);
      margin-top: var(--space-6);
      &.on-top {
        border-top: none;
        padding-top: 0;
      }
      .teaser-link {
        display: flex;
        .container {
          padding: 0;
          margin-right: 49px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .summary {
          @include summary-block(0, 100%);
          width: auto;
          border-left: none;
          padding-left: 0;
          p:not(:only-child) {
            @include line-clamp(4);
          }
          li {
            position: relative;
            left: var(--space-4);
          }
        }
        .teaser-image-wrapper {
          width: 467px;
          height: 311px;
          margin-bottom: 0;
          flex-shrink: 0;
        }
        .title {
          margin-bottom: var(--space-2);
          font-size: 32px;
          line-height: 36px;
          letter-spacing: -0.5px;
        }
        .information {
          border-bottom: none;
        }
        .byline {
          text-transform: initial;
        }
      }

      &.breaking .title {
        font-size: 38px;
        line-height: 40px;
        letter-spacing: -1px;
      }
      &.inverted {
        margin: var(--space-2) calc(-1 * var(--space-4) + 2px);
        padding: var(--space-6);

        .summary,
        .summary h1,
        .kicker {
          color: var(--c-neutral-3);
        }
        .title,
        .teaser-link:hover,
        .context-label {
          color: var(--c-light);
        }
      }
      &.magazine .title {
        font-size: 46px;
        line-height: 46px;
        letter-spacing: 0.3px;
      }
      &.history-magazine .title {
        font-size: 38px;
        line-height: 38px;
      }
      &.feature,
      &.magazine,
      &.history-magazine {
        width: auto;
        height: auto;

        .teaser-link {
          max-width: 811px;
          margin: 0 auto;
          align-items: center;
        }
        &:not(.inverted) {
          .context-label,
          .kicker,
          .title {
            color: var(--c-dark);
            border-color: initial;
          }
        }
        .information {
          justify-content: center;
          margin-bottom: var(--space-2);
          .relevant-tag {
            display: none;
          }
        }
        .teaser-image-wrapper {
          width: 381px;
          height: 381px;
          .teaser-image {
            width: 100%;
            height: 100%;
          }
        }
        .container {
          text-align: center;
          height: 381px;
          justify-content: center;
          max-width: 381px;
          margin-right: 49px;
          position: relative;
        }

        .summary {
          p {
            @include line-clamp(8);
            &:not(:only-child) {
              @include line-clamp(5);
            }
          }
        }

        .kicker,
        .title,
        .summary {
          text-align: center;
        }
      }
    }
  }
}
