.audio-container {
  height: 30px;
  background: var(--c-light);
  font-family: Graphik;
  .buffered-amount,
  .progress-amount {
    display: block;
    height: 100%;
    width: 0;
  }
  .buffered {
    display: none;
    height: 2px;
    border-radius: 6px;
    position: relative;
    background: var(--c-shade-10);
    .buffered-amount {
      background: var(--c-shade-20);
    }
  }
  .progress {
    display: none;
    height: 2px;
    border-radius: 6px;
    position: relative;
    top: -2px;
    .progress-amount {
      background-color: var(--c-dark);
    }
  }
  .current-time {
    margin-right: var(--space-1);
    font-size: 12px;
    line-height: 16px;
  }
  .duration {
    margin-left: var(--space-1);
    font-size: 12px;
    line-height: 16px;
  }
  .speed-container {
    display: none;
    font-size: 12px;
    line-height: 16px;

    button {
      background: transparent;
      border: none;
    }
    .active-speed {
      color: var(--c-primary-03);
    }
  }

  .speed-controls {
    font-size: 12px;
    line-height: 20px;
  }

  .thumbnail {
    display: block;

    .start-button,
    .start-button-hermes {
      position: relative;
      background: #d45516;
      border: none;
      border-radius: 100px;
      padding: 7px 16px 7px 34px;
      font-family: Produkt;
      font-size: 11px;
      line-height: 16px;
      font-weight: 300;
      color: var(--c-light);
      &::before {
        content: '';
        background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/audio/play.svg');
        background-repeat: no-repeat;
        height: 11px;
        width: 8px;
        position: absolute;
        top: 10px;
        left: 18px;
        filter: invert(0);
        padding: 0px 12px;
      }
    }
  }

  .controls {
    display: none;
    justify-content: space-between;
    margin-bottom: 10px;

    .left {
      display: flex;

      .time-labels {
        margin-left: 12px;
      }
    }
  }
  .play-button {
    background: transparent;
    border: none;
    padding: 2px 0 0;
    &::before {
      content: '\00a0';
      background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/audio/play.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      filter: invert(1);
      display: block;
      width: 14px;
      height: 14px;
    }
    &.pause {
      &::before {
        background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/audio/pause.svg');
        width: 12px;
        height: 12px;
      }
    }
  }
}

.audio-container.top {
  text-align: left;
  padding: 0 0 var(--space-4);
}

.audio-container.bottom {
  padding: 0;
  text-align: right;
}

.teaser.large .audio-container.bottom {
  padding: 0 16px;
}

.teaser.large .audio-container.top {
  padding: 12px 16px;
}

.audio-container {
  &.feature,
  &.inverted {
    background: var(--c-dark);
    height: 34px;

    .buffered {
      background: rgba(255, 255, 255, 0.5);
    }
    .progress .progress-amount {
      background-color: var(--c-light);
      border-radius: 6px;
    }

    .controls {
      color: var(--c-light);
    }
    .speed-container {
      button {
        color: var(--c-light);
      }
      .active-speed {
        color: var(--c-semantic-info-tint-3);
      }
    }

    .start-button,
    .start-button-hermes {
      background: var(--c-dark);
      border: 2px solid var(--c-shade-10);
    }

    .start-button {
      border-color: rgba(255, 255, 255, 0.5);
      color: var(--c-light);
      &::before {
        filter: invert(0);
      }
    }

    .play-button {
      border-color: rgba(255, 255, 255, 0.5);
      &::before {
        filter: invert(0);
      }
    }
  }
}
