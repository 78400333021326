.teaser-bundle.desktop {
  .bundle-grid.large {
    .teaser.bundle-secondary {
      height: 139px;
    }
    .teaser.bundle-secondary-horizontal {
      position: relative;
      display: flex;
      width: 100%;
      padding: var(--space-4) var(--space-4) var(--space-4) 0;
      margin-bottom: 0;
      border-bottom: var(--divider-line);

      .text-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .teaser-link {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .teaser-image-wrapper,
      .teaser-image {
        width: 209px;
        height: 139px;
        flex-shrink: 0;
      }
      .title {
        @include title(18px, 21px);
        margin-right: var(--space-4);
      }
      .text-left + .byline {
        display: flex;
        flex-direction: column-reverse;
        min-width: 120px;
        .author-image-wrapper {
          margin-left: 0;
        }
        .author-name,
        .author-title {
          margin-right: 0;
          text-align: end;
        }
      }

      &.opinion .title {
        font-style: italic;
      }
      &:first-of-type {
        border-top: var(--divider-line);
        padding-top: 0;
      }
      &:last-of-type {
        border-bottom: none;
      }
      &:only-of-type {
        flex-shrink: 0;
      }
    }

    // large kort forklart
    .secondary-container {
      .teaser.bundle-secondary-horizontal {
        &:first-of-type {
          border-top: none;
          border-bottom: none;
          border-right: var(--divider-line);
          margin: var(--space-4) 0;
          padding-bottom: 0;
          padding-right: var(--space-6);

          .teaser-image-wrapper {
            margin-left: var(--space-6);
          }
        }
        &:last-of-type {
          padding-right: 0;
          padding-left: var(--space-6);
        }
      }
    }
    .tertiary-container {
      border-top: var(--divider-line);

      .teaser.bundle-secondary {
        padding: var(--space-4) 0;
        height: auto;
        &:not(:first-of-type) {
          padding-left: var(--space-6);
        }
      }
      &.wrapped {
        .text-wrapper-small {
          min-width: 209px;
        }
      }
    }
    &.double-tertiary .tertiary-container {
      @for $i from 1 through 4 {
        .teaser.bundle-secondary:nth-child(#{$i}) {
          border-bottom: var(--divider-line);
        }
      }
      .teaser.bundle-secondary:nth-child(4) .container {
        padding-right: 0;
        border-right: 0;
      }
      .teaser.bundle-secondary:nth-child(5) {
        padding-left: 0;
      }
      .teaser.bundle-secondary {
        &:nth-child(5),
        &:nth-child(6) {
          grid-column: span 11;
        }
        &:nth-child(7) {
          grid-column: span 10;
        }
      }
    }
    &.single-secondary .teaser.bundle-secondary {
      height: auto;
      border-top: var(--divider-line);
      padding: var(--space-4) 0;
    }

    .right {
      .tertiary-container {
        grid-auto-columns: 1fr;
        .teaser.bundle-secondary {
          height: 139px;
        }
      }
      .teaser.bundle-secondary-horizontal {
        padding-right: 0;
        &:first-of-type {
          border-top: none;
        }
        &:only-of-type {
          padding-top: var(--space-4);
          border-top: var(--divider-line);
        }
      }
    }
  }

  &.inverted {
    .bundle-grid.large {
      .teaser.bundle-secondary,
      .teaser.bundle-secondary-horizontal {
        border-color: var(--c-tint-30) !important;
      }
      .teaser.bundle-secondary-horizontal {
        &:only-of-type {
          padding-top: var(--space-4);
        }
        .teaser-link {
          &:hover,
          &:visited {
            color: var(--c-light);
          }
        }
      }
      .tertiary-container {
        margin-top: 1px;
        border-color: var(--c-tint-30);
      }
    }
  }
}
