.navigation-widget-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .image-frame {
    max-width: 375px;
    height: 300px;
    background-color: #fff;
    margin: 0 auto;
    .image {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: center;
      filter: grayscale(30%) hue-rotate(90deg) brightness(115%);
    }
  }
  .link-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .link-wrapper a {
    border: 1px solid var(--c-text-link);
    border-radius: 30px;
    padding: 8px 16px;
    text-align: center;
    color: var(--c-text-link);
    font-family: $fontGraphik;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}
