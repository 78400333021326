:root {
  --c-primary-01: #0533ff;
  --c-primary-02: #dfe3fc;
  --c-primary-03: #002ec4;
  --c-primary-04: #000724;

  --c-text-ingress-and-body: #333339;

  --c-light: #ffffff;
  --c-dark: #000724;
  --c-shade: #000000;
  --c-shade-2: #001b7c;
  --c-shade-10: #0007241a;
  --c-background-blue: #d8e8fc;
  --c-elements-backgrounds-blue: #eef3f9;
  --c-breaking-light: #c8163c;
  --c-breaking-dark: #eb1a46;
  --c-oslo-primary-2: #d82c2c;

  --c-grey: #e8f1fa33;
  --c-grey-5: #f4f8fa;

  --c-semantic-success-shade-1: #0e6655;
  --c-semantic-success-tint-1: #d0ece7;

  --c-semantic-warning-shade-1: #b28900;
  --c-semantic-warning-shade-2: #735800;
  --c-semantic-warning-tint-2: #faf6e6;
  --c-semantic-warning-tint-3: #f0d77e;

  --c-semantic-info-tint-3: #6484ff;
  --c-text-informational: #50535a;

  --c-blue-10: #eef3f9;
  --c-blue-30: #d8e8fc;
  --c-blue-50: #00259d;
  --c-blue-60: #001874;

  --c-yellow-50: #ffe85f;

  --c-neutral-01: #f7f7f7;
  --c-neutral-02: #e9e9e9;
  --c-neutral-03: #dbdbdb;
  --c-neutral-04: #bfbfbf;
  --c-neutral-05: #a3a3a3;
  --c-neutral-06: #767676;
  --c-neutral-07: #656565;
  --c-neutral-08: #4f4f4f;
  --c-neutral-09: #333333;
  --c-neutral-10: #ffffff4d;

  --c-elements-background-grey: #ebf0f4;

  --c-divider-widget: rgba(0, 7, 36, 0.5);

  --c-text-link: #00259d;
  --c-text-link-hover: #003a90;
  --c-info: #6484ff;
  --c-hyperlink-pressed: #8d00b7;

  --c-tint-30: rgba(255, 255, 255, 0.3);

  --c-shade-10: rgba(0, 7, 36, 0.1);
  --c-shade-30: rgba(0, 7, 36, 0.3);
  --c-shade-50: rgba(0, 7, 36, 0.5);
  --c-shade-66: rgba(0, 7, 36, 0.66);
  --c-shade-70: rgba(0, 7, 36, 0.7);
  --c-background: #f1f1f1;

  --c-linear-gradient: linear-gradient(
    180deg,
    rgba(0, 7, 36, 0) 0%,
    rgba(0, 7, 36, 0.00570715) 6.67%,
    rgba(0, 7, 36, 0.0234366) 13.33%,
    rgba(0, 7, 36, 0.0538955) 20%,
    rgba(0, 7, 36, 0.0972915) 26.67%,
    rgba(0, 7, 36, 0.152971) 33.33%,
    rgba(0, 7, 36, 0.219043) 40%,
    rgba(0, 7, 36, 0.292176) 46.67%,
    rgba(0, 7, 36, 0.367824) 53.33%,
    rgba(0, 7, 36, 0.440957) 60%,
    rgba(0, 7, 36, 0.507029) 66.67%,
    rgba(0, 7, 36, 0.562709) 73.33%,
    rgba(0, 7, 36, 0.606104) 80%,
    rgba(0, 7, 36, 0.636563) 86.67%,
    rgba(0, 7, 36, 0.654293) 93.33%,
    rgba(0, 7, 36, 0.66) 100%
  );

  --space-1: 4px;
  --space-2: calc(var(--space-1) * 2);
  --space-3: calc(var(--space-1) * 3);
  --space-4: calc(var(--space-1) * 4);
  --space-5: calc(var(--space-1) * 5);
  --space-6: calc(var(--space-1) * 6);
  --space-7: calc(var(--space-1) * 7);
  --space-8: calc(var(--space-1) * 8);
  --space-9: calc(var(--space-1) * 9);
  --space-10: calc(var(--space-1) * 10);
  --space-11: calc(var(--space-1) * 11);
  --space-12: calc(var(--space-1) * 12);
  --space-13: calc(var(--space-1) * 13);
  --space-14: calc(var(--space-1) * 14);
  --space-15: calc(var(--space-1) * 15);
  --space-16: calc(var(--space-1) * 16);

  --image-radius: var(--space-1);

  --divider-line: 1px solid var(--c-shade-10);
  --divider-line-ads: 1px solid var(--c-shade-50);
  --divider-line-bundle: 1px solid var(--c-shade-50);
  --divider-line-bundle-strong: 2px solid var(--c-primary-04);
  --divider-line-widget: 1px solid var(--c-divider-widget);
}
