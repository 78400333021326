#push-down-banner {
  position: relative;
  z-index: 100000;
}

header.ch-core-header > div.ch-header-main {
  position: absolute;
}

#push-down-banner ~ .ch-core-header {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0;

  .ch-header-main {
    position: absolute !important;
  }

  #ch-menu {
    position: absolute !important;
  }
}

// to make header position: sticky; works for mobile
#inner-container {
  overflow: unset !important;
}

#simplified-login-widget > * {
  z-index: 100000 !important;
}
