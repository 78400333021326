$nextPageLinkBackgroundColor: $color-brand !default;
$nextPageLinkColor: $color-text-invert !default;

.link__nextPage-container {
  margin: 0 auto;
  display: table;

  @media (max-width: 415px) {
    margin: 10px auto;
  }
}

a.link__nextPage,
a.link__nextPage:visited,
a.link__nextPage:link {
  color: $nextPageLinkColor;
  border-radius: 5px;
  background-color: $nextPageLinkBackgroundColor;
  padding: 10px 30px;
  display: table-cell;
}
