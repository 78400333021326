@import '_bundle-desktop-main.scss';
@import '_bundle-desktop-secondary.scss';
@import '_bundle-desktop-secondary-large.scss';

$bundleElementBase: 209px;

.teaser-bundle.desktop {
  border-bottom: var(--divider-line-bundle-strong);
  border-top: var(--divider-line-bundle-strong);
  position: relative;
  margin: 0 0 var(--space-6) 0;

  .teaser {
    max-width: inherit;
  }

  .feature {
    text-align: start;
  }

  .teaser-section-title {
    @include label-2(var(--c-dark), 600);
    .section-icon {
      margin-left: 2px;
    }
  }

  .author-name,
  .author-title {
    text-transform: none;
  }

  .byline {
    margin-bottom: 0;
  }

  .top-container {
    display: flex;
  }

  .top-double {
    width: 100%;
  }

  .bottom-container {
    display: flex;
    border-top: var(--divider-line);

    &:empty {
      border-top: none;
    }
    height: 100%;

    .teaser {
      flex: 1 1 0px;
    }
  }

  &.inverted {
    .teaser.bundle-main.desktop,
    .teaser.bundle-secondary .container,
    .top-double .teaser.bundle-secondary:first-child,
    .top-container,
    .bottom-container,
    .teaser.bundle-main.desktop .main-summary {
      border-color: var(--c-tint-30);
    }
    .teaser-section-title {
      color: var(--c-light);
    }
  }

  .teaser.bundle-main.desktop.illustration,
  .teaser.bundle-secondary.illustration,
  .teaser.bundle-secondary-horizontal.illustration {
    .author-image-wrapper {
      &::after {
        background: none;
      }
      .author-image {
        filter: none;
      }
    }
  }

  .with-byline-image {
    .author-image {
      width: 56px;
      height: 56px;
    }
    &.illustration .author-image {
      width: 86px;
      height: 86px;
    }
  }

  @include teaser-series-top('.teaser', '.series-header');

  .bundle-grid.large {
    display: grid;
    grid-template-columns: repeat(2, 491px);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .right {
      display: flex;
      align-items: flex-end;
      padding-left: var(--space-6);
    }
    &.flex-row .right {
      flex-direction: column;
      justify-content: flex-start;
      padding-top: var(--space-4);
    }
    .bottom-container {
      flex-direction: column;
      grid-area: 2 / 1 / 3 / 3;
      border-top: none;
    }
    .tertiary-container {
      width: 100%;
      display: grid;
      grid-auto-columns: minmax(209px, 491px);
      grid-auto-flow: column;
      &.wrapped {
        grid-area: 2 / 1 / 3 / 3;
      }
    }
    &.double-tertiary .tertiary-container {
      grid-template-columns: repeat(32, 1fr);
      grid-template-rows: 1fr 1fr;
      grid-auto-flow: row;
      .teaser.bundle-secondary {
        grid-column: span 8;
      }
    }
    .secondary-container {
      display: flex;
      flex-direction: row;
    }
  }
}

// A bundle has a dark divider line on top only if the element before it doesn't have a dark bottom.
// Remove this element's dark top border if the previous element is a bundle
.teaser-bundle.desktop + .teaser-bundle.desktop {
  border-top: none;
  .bundle-grid {
    border-top: none;
  }
}

// no top border if the bundle is the very first child in the feed
.top-block .teaser-bundle.desktop:first-child {
  border-top: none;
}

// block then bundle. move upwards and overlap block's border
.block + .teaser-bundle.desktop {
  margin-top: calc((var(--space-6) + 1px) * -1);
}

// a block preceded by a bundle won't need a border-top
.teaser-bundle.desktop + .block {
  border-top: none;
  padding-top: 0;
}

.teaser-bundle.desktop.inverted + .block.beta {
  margin-top: var(--space-4);
}

.widget-oppsummert
  + .widget-followed-content
  + .content-container
  .teaser-bundle.desktop {
  margin-top: calc(var(--space-2) * -1);
}
