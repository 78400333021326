.bundle-podcast {
  padding: var(--space-4);
  .container {
    display: flex;
    img {
      width: 94px;
      height: 94px;
      margin-right: var(--space-4);
      border-radius: 4px;
    }
  }
  .pod-title {
    font-family: $fontGraphik;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    margin-bottom: var(--space-1);
  }
  .pod-duration {
    @include description(12px, 16px, var(--c-neutral-05));
    padding-bottom: var(--space-2);
  }
  .text-container {
    display: flex;
    flex-direction: column;
  }
  .pod-button {
    border-radius: 4px;
    background-color: var(--c-primary-02);
    border: none;
    padding: 7px;
    text-align: center;
    @include label-2(var(--c-neutral-09), 600);
    display: flex;
    justify-content: center;
    width: 184px;
  }
  .pod-icon {
    width: 16px;
    height: 15px;
    background: url('https://cdn.aftenposten.no/ap/images/podcasts/volume_up_black_24dp.svg');
    background-size: cover;
    margin-right: var(--space-1);
  }

  &.inverted {
    .pod-button {
      background-color: var(--c-neutral-01);
    }
  }
}
