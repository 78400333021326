/* Stories overlay from @vgtv/stories-overlay */
.stories-overlay {
  z-index: 2147483647;
}

.reels-widget {
  margin-bottom: var(--space-4);
}

@media (min-width: 765px) {
  .reels-widget {
    border: 1px solid #0c0c23;
    border-width: 1px 0;
    padding: var(--space-6) 0;
    margin-bottom: var(--space-6);
  }
}
