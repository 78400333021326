$cdnHost: 'https://cdn.aftenposten.no';

$c-blue-40: #0533ff;
$c-blue-50: #00259d;
$c-blue-30: #d8e8fc;
$c-dark-shade-10: #000724;

$c-dark-shade-alfa-30: #0007241a;
$c-light-tint-30: #ffffff4d;
$c-gray-30: #dbdbdb;
$c-gray-70: #656565;
$c-gray-90: #333333;

.teaser-audio-player {
  --text-color: black;
  --controls-text-color: #{$c-gray-90};
  --controls-text-color-shade: #{$c-dark-shade-10};
  --teaser-divider-color: #{$c-light-tint-30};
  --track-color: #{$c-blue-30};
  --progress-color: #{$c-blue-50};
  --play-icon-url: url('#{$cdnHost}/ap/images/functional_icons/audio/play_rounded_blue.svg');
  --pause-icon-url: url('#{$cdnHost}/ap/images/functional_icons/audio/pause_rounded_blue.svg');

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: solid 1px $c-dark-shade-alfa-30;
  text-align: initial;
  color: var(--text-color);

  .podcast-thumbnail {
    width: 72px;
    border-radius: 4px;
    object-fit: contain;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .headings {
      display: flex;
      flex-direction: row;
      gap: 4px;
      margin-bottom: 7px;
      font-family: $fontGraphik;
      color: var(--text-color);

      h3,
      h4 {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }

      h4 {
        font-weight: 400;
      }
    }

    .player {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      margin: 8px 0;
      font-family: $fontGraphik;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: var(--controls-text-color);
      height: 16px;

      .btn-play {
        display: flex;
        align-items: center;
        gap: 8px;
        border: none;
        background: none;
        padding: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: var(--controls-text-color);
        cursor: pointer;
        min-width: 20px;

        img.play-icon {
          width: 16px;
          height: 16px;
          padding: 10px;
          border-radius: 50%;
          border: solid 1px var(--progress-color);
          filter: grayscale(1);
          content: var(--play-icon-url);
        }
      }

      &.playing {
        .btn-play img.play-icon {
          content: var(--pause-icon-url);
        }
      }

      .progress-bar {
        position: relative;
        flex-grow: 2;
        text-align: initial;
        cursor: pointer;

        .track,
        .progress {
          display: inline-block;
          position: absolute;
          width: 100%;
          height: 4px;
          border-radius: 4px;
        }

        .track {
          background-color: var(--track-color);
          z-index: 10;
        }

        .progress {
          background-color: var(--progress-color);
          z-index: 100;
          width: 25%;
        }
      }

      .duration {
        font-weight: 400;
        color: var(--controls-text-color-shade);

        &.verbose {
          min-width: 80px;
          text-align: end;
        }
      }
    }
  }
}

.teaser-audio-player.podcast {
  .headings {
    flex-direction: column;
  }
}

.teaser.feature {
  .teaser-audio-player {
    --text-color: white;
    --controls-text-color: #{$c-blue-30};
    --track-color: white;
    --progress-color: #{$c-blue-40};
    --play-icon-url: url('#{$cdnHost}/ap/images/functional_icons/audio/play-rounded.svg');
    --pause-icon-url: url('#{$cdnHost}/ap/images/functional_icons/audio/pause_rounded.svg');

    padding: 16px 16px;
    padding-bottom: 24px;
    margin-top: 0px;
    background-color: $c-dark-shade-10;
    border-top: none;
  }
}

.teaser.large {
  .teaser-audio-player {
    margin-top: 16px;
  }
}

.teaser.large:has(.teaser-audio-player) {
  padding-bottom: 16px;
}

.teaser.medium:has(.teaser-audio-player) {
  padding-bottom: 18px;
}

.teaser.feature:has(.teaser-audio-player) {
  text-align: initial;

  .feature-container {
    .container-linear-gradient {
      align-items: flex-start;
    }

    .container-darken {
      padding: 0 var(--space-4) var(--space-6);
    }

    .title {
      font-size: 26px;
      line-height: 30px;
    }
  }
}

.teaser.inverted {
  .teaser-audio-player {
    --controls-text-color: #{$c-gray-30};
    --controls-text-color-shade: #{$c-gray-30};
    --teaser-divider-color: #{$c-light-tint-30};

    border-top: solid 1px $c-light-tint-30 !important;

    img.play-icon {
      filter: grayscale(1) invert(1) !important;
    }
  }
}
