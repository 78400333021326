.teaser .title {
  white-space: pre-line;
}

.top-fullwidth.mobile {
  // Topstripe
  .teaser.topstripe.small {
    .title {
      @include title(22px, 26px);
    }

    .title.short {
      @include title(26px, 30px);
    }

    .kicker ~ .title.short {
      @include title(22px, 26px);
    }

    &:not(.with-image) .title {
      @include title(26px, 30px);
    }
  }

  // Topstripe + breaking
  .teaser.topstripe.small.breaking {
    .title {
      @include title-breaking(22px, 24px);
    }

    .title.short {
      @include title-breaking(28px, 30px);
    }

    .kicker ~ .title.short {
      @include title-breaking(22px, 24px);
    }

    &:not(.with-image) .title {
      @include title-breaking(28px, 30px);
    }
  }
}

.teaser.large .title {
  @include title(26px, 30px);
}

.teaser.medium .title {
  @include title(22px, 26px);
}

.teaser.small {
  .title {
    @include title(18px, 21px);
  }
}

// opinion
.teaser.opinion.large .title {
  @include title(26px, 30px, italic);
}

.teaser.opinion.medium .title {
  @include title(22px, 26px, italic);
}

.teaser.opinion.small .title {
  @include title(18px, 21px, italic);
}

.teaser.opinion.with-byline.large .title {
  margin-bottom: var(--space-8);
}

.teaser.opinion.with-byline.medium .title {
  margin-bottom: var(--space-4);
}

.teaser.opinion.with-byline.small .title {
  margin-bottom: var(--space-4);
}

.teaser.opinion.with-byline-image .title {
  margin-bottom: var(--space-4);
}

.teaser.opinion.illustration .title {
  margin-bottom: var(--space-2);
}

// breaking
.teaser.breaking.large .title {
  @include title-breaking(32px, 34px);
}

.teaser.breaking.medium .title {
  @include title-breaking(28px, 30px);
}

.teaser.breaking.small {
  .title {
    @include title-breaking(18px, 21px, -0.3px);
  }
}

// magazine
.teaser.magazine.large .title {
  @include title-magazine(32px, 36px);
}

.teaser.magazine.medium .title {
  @include title-magazine(26px, 30px);
}

.teaser.magazine.small .title {
  @include title-magazine(18px, 21px);
}

// magazine history
.teaser.history-magazine.large .title {
  @include title-history-magazine(26px, 30px);
}

.teaser.history-magazine.medium .title {
  @include title-history-magazine(22px, 26px);
}

.teaser.history-magazine.small .title {
  @include title-history-magazine(18px, 21px);
}

// feature
.teaser.feature .title {
  font-weight: 400;
  color: var(--c-light);
}
.teaser.feature.magazine {
  @include title-magazine(26px, 30px);
}

.teaser.multimedia {
  &.medium .title {
    @include title(22px, 26px);
    margin-bottom: var(--space-2);
  }

  &.large .title {
    @include title(26px, 30px);
  }
}

.teaser.memorial .title {
  @include title(18px, 21px, normal, normal);
  margin-bottom: var(--space-6);
}

// bundle
.teaser.bundle-main .title,
.teaser.bundle-main.feature .title {
  @include title(26px, 30px);
  margin-bottom: var(--space-6);
  color: var(--c-dark);
}

.large .teaser.bundle-main .title,
.large .teaser.bundle-main.feature .title {
  @include title(30px, 34px);
}

.teaser.bundle-main.inverted .title {
  color: var(--c-light);
}

.teaser.breaking.bundle-main .title {
  @include title-breaking(28px, 30px);
  margin-bottom: var(--space-6);
}

.large .teaser.breaking.bundle-main .title {
  @include title-breaking(30px, 34px);
}

.teaser.bundle-secondary .title,
.teaser.bundle-secondary.feature .title {
  @include title(18px, 21px);
  margin-bottom: var(--space-4);
  color: var(--c-dark);
}

.teaser.magazine.bundle-main .title {
  @include title-magazine(32px, 32px);
  margin-bottom: var(--space-6);
}

.large .teaser.magazine.bundle-main .title {
  @include title-magazine();
}

.teaser.magazine.bundle-secondary .title {
  @include title-magazine(24px, 24px);
  margin-bottom: var(--space-4);
}

.teaser.feature.bundle-main .title {
  font-weight: 400;
}

.large .teaser.feature.bundle-main .title {
  font-weight: 400;
}

// desktop
@media (min-width: 765px) {
  .teaser.topstripe.small .title {
    @include title(26px, 30px);

    // If we want to clamp number of lines in the title in the future (designer
    // wanted it to look ugly for now to discourage long titles):

    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 2;
    // line-clamp: 2;
    // overflow: hidden;
  }
  // magazine history
  .teaser.history-magazine.large .title {
    @include title-history-magazine(32px, 36px);
  }

  .teaser.history-magazine.medium .title {
    @include title-history-magazine(22px, 26px);
  }

  .teaser.history-magazine.small .title {
    @include title-history-magazine(22px, 26px);
  }

  .teaser.medium .title {
    @include title(16px, 20px);
  }

  .teaser.history-magazine.medium .title {
    @include title-history-magazine(22px, 26px);
  }

  .teaser.history-magazine.small .title {
    @include title-history-magazine(22px, 26px);
  }

  .teaser.small .title {
    @include title(22px, 26px);
  }
  .teaser.medium .title {
    @include title(22px, 26px);
  }
  .teaser.large .title {
    @include title(32px, 36px);
  }
  .teaser.medium.inverted.feature .title {
    @include title-breaking();
  }

  .teaser.feature .title {
    @include title();
    font-weight: 400;
    color: var(--c-light);
  }

  .teaser.topstripe.breaking.small {
    .title {
      @include title-breaking(28px, 30px, -0.3px);
    }
  }
  .teaser.opinion.small .title {
    @include title(22px, 26px, italic);
  }
  .teaser.opinion.medium {
    .title {
      @include title(22px, 26px, italic);
      margin-bottom: var(--space-4);
    }

    &.kort-forklart .title {
      @include title(32px);
      margin-bottom: var(--space-2);
    }
  }
  .teaser.opinion.large .title {
    @include title(32px, 36px, italic);
  }

  .teaser.large.inverted .title {
    word-break: break-word;
  }

  .teaser.large.inverted.illustration .title {
    max-width: 395px;
  }

  .full-width.regular .teaser.horizontal.inverted .title,
  .full-width.regular .teaser.small.inverted .title {
    @include title();
  }

  .full-width.big .teaser.horizontal.inverted .title,
  .full-width.big .teaser.small.inverted .title {
    @include title(32px, 36px);
  }

  // full width on top

  .full-width-top.regular .teaser.horizontal .title,
  .full-width-top.regular .teaser.small .title {
    @include title();
  }

  .full-width-top.big .teaser.horizontal .title,
  .full-width-top.big .teaser.small .title {
    @include title(32px, 36px);
  }

  .full-width-top.regular .teaser.breaking.horizontal .title,
  .full-width-top.regular .teaser.breaking.small .title {
    @include title-breaking();
    margin-bottom: var(--space-4);
  }

  .full-width-top.big .teaser.breaking.horizontal .title,
  .full-width-top.big .teaser.breaking.small .title {
    @include title-breaking(38px, 40px);
  }

  // magazine
  .teaser.magazine.small .title {
    @include title-magazine(26px, 30px);
    margin-bottom: var(--space-4);
  }
  .teaser.magazine.medium .title {
    @include title-magazine(26px, 30px);
  }
  .teaser.magazine.large .title {
    @include title-magazine(36px, 40px);
  }

  // magazine history
  .teaser.feature.history-magazine .title {
    @include title-history-magazine();
  }

  //breaking

  .block.x-large .teaser.breaking.large .title {
    @include title-breaking(38px, 40px);
    margin-bottom: var(--space-8);
  }

  .teaser.breaking.large .title {
    @include title-breaking(38px, 40px);
  }

  .teaser.breaking.medium .title {
    @include title-breaking(24px, 26px);
  }

  .teaser.breaking.small .title {
    @include title-breaking(24px, 26px);
  }

  .top-block .teaser.large {
    .title {
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -0.3px;
    }
  }

  .teaser.memorial .title {
    @include title(22px, 26px, normal, normal);
  }

  .teaser.inverted .title {
    color: var(--c-light);
  }

  .block.x-large .teaser.large .title {
    @include title(32px, 36px);
  }

  .medium .teaser.medium {
    .title {
      @include title(18px, 21px);
    }
    &.opinion .title {
      font-style: italic;
    }
  }

  .text-only div:only-child .teaser.medium {
    .title {
      @include title(22px, 26px);
    }
    &.opinion .title {
      font-style: italic;
    }
  }
}
