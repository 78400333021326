// advertory styles

:root {
  --ads-sidebar-displacement-width: 130px;
}

.skyscraper-r {
  position: absolute;
  right: calc(50% - 685px);
  width: 180px;
  height: 100%;
  top: 0;
  z-index: 1;
}

.skyscraper-l {
  position: absolute;
  left: calc(50% - 685px);
  width: 180px;
  height: 100%;
  top: 0;
  z-index: 1;
}

.skyscraper-inner {
  top: 75px;
  position: sticky;
}

.contentboard {
  position: relative;
}

.top-ads {
  width: 1010px;
  margin: auto;
}
