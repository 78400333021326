.app-redirection-page#fakse {
  & a {
    display: block;
    width: 80%;
    margin: 80px auto 0px auto;
    text-align: center;
  }

  & a img {
    width: 100%;
    max-width: 264px;
  }

  & > img {
    display: block;
    width: 60%;
    max-width: 264px;
    margin: 5vh auto;
  }

  & #ios {
    margin: 20px auto;
  }
}
