$link-color: rgb(51, 51, 51);
$link-border-color: rgb(5, 51, 255);
$link-color-hover: $link-border-color;
$link-border-color-hover: $link-border-color;
$link-color-visited: #8d00b7;
$link-border-color-visited: $link-color-visited;
$mark-color: #f4e498;

.tag-header,
.story-header {
  h1 {
    @include title(30px, 34px);
  }
  @include respond-to(mobile-only) {
    margin: 0 1em;
  }

  .tag-header-description,
  .story-header-description {
    @include description(18px, 24px, var(--c-neutral-07));

    @include respond-to(mobile-only) {
      font-size: calc(4px + 0.75rem);
    }
  }

  .tag-header-description {
    margin-top: 0;
    margin-bottom: 2em;

    a {
      color: $link-color;
      padding-bottom: 1px;
      border-bottom: 1px solid;
      border-bottom-color: $link-border-color;
      word-wrap: break-word;

      &:hover {
        color: $link-color-hover;
        border-bottom-color: $link-border-color-hover;
      }

      &:visited {
        border-bottom-color: $link-border-color-visited;
        &:hover {
          color: $link-color-visited;
        }
      }
    }

    em {
      font-style: italic;
    }

    .story-header-description {
      margin: 0.85em 0;

      h1 {
        line-height: 1.2;
      }

      h2 {
        font-size: 1.5em;
        margin: 1.1em 0;
      }

      p {
        margin-bottom: 0.85em;

        &.quote {
          border-left: 0.2em solid #ccc;
          padding-left: 1em;
          margin: 1.4em 4em;
          font-style: italic;

          @include respond-to(mobile-only) {
            margin: 1.4em 1.5em;
          }
        }
      }

      mark {
        background-color: $mark-color;
      }
    }
  }
}
