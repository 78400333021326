@mixin author-pic($size: 45px, $rightMargin: 12px, $grayscale: 90%) {
  width: $size;
  height: $size;
  overflow: hidden;
  background-size: cover;
  padding: 0;
  display: inline-block;
  background-position: center;
  border-radius: 50%;
  margin-right: $rightMargin;
  border: 2px solid white;
  filter: grayscale($grayscale);
  flex-shrink: 0;
}
