.teaser-bundle.desktop {
  // baseline styles
  .teaser.bundle-secondary {
    width: -webkit-fill-available;
    padding: var(--space-4) var(--space-6) var(--space-5) 0;

    &.top-right {
      padding: var(--space-4) 0 var(--space-4) var(--space-6);
      .teaser-link {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .teaser-image-wrapper {
        margin-left: 0;
        margin-bottom: var(--space-2);
        .teaser-image {
          width: 209px;
          height: 139px;
        }
      }
      .byline {
        width: 100%;
        .author-name {
          margin-bottom: 0;
        }
      }

      &.one-to-one {
        .teaser-image-wrapper .teaser-image {
          height: 209px;
        }
      }
    }
    &.feature,
    &.magazine,
    &.history-magazine {
      .title {
        @include title-feature(16px, 20px, 600);
      }
    }

    .container {
      padding-top: 0;
      border-top: none;
      border-right: var(--divider-line);
      padding-right: var(--space-6);
      height: 100%;

      [class^='author-image-wrapper'],
      .teaser-image-wrapper {
        flex-shrink: 0;
        height: fit-content;
      }
    }

    .title {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: var(--space-4);
      @include line-clamp(4);
    }

    &.opinion .title,
    .kicker + .title {
      @include line-clamp(3);
    }

    &:last-child {
      padding-right: 0;
      .container {
        padding-right: 0;
        border-right: none;
      }
    }
  }

  // medium: two secondary articles wrapped in the right side of the top row
  .top-double .teaser.bundle-secondary:first-child {
    border-bottom: var(--divider-line);
    padding-left: 0;
    margin-left: var(--space-6);
  }

  .bottom-container .teaser.bundle-secondary {
    .text-wrapper-small {
      min-width: 295px;
    }
    &.with-byline-image {
      .text-wrapper-small {
        min-width: 235px;
      }
    }
    &.illustration {
      .text-wrapper-small {
        min-width: 209px;
      }
    }
    &:only-child .title {
      font-size: 22px;
      line-height: 26px;
    }
    .author-name {
      margin-bottom: 0;
    }
  }
  .bottom-container.tiny-secondary
    .teaser.bundle-secondary
    .text-wrapper-small {
    min-width: 209px;
  }
}
