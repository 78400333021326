@import 'styles-common/_cdn-fonts';
@import 'styles-common/variables';
@import '../../../public-src/core/scss/helpers/mixins';

// The baseline is mobile first and classic braze cards — variant overrides are at the bottom of the file
.braze.content-card {
  --card-font-family: $fontGraphik;
  --card-title-color: var(--c-primary-04);
  --card-description-color: var(--c-neutral-09);
  --card-bg-color: #fff;
  --card-border-color: rgba(0, 7, 36, 0.1);

  .ab-feed {
    width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    border: none;
  }

  .ab-feed .ab-card {
    margin: var(--space-4) var(--space-4) var(--space-6);

    @include respond-to(tablet) {
      margin: 0 0 var(--space-6);

      &::after {
        content: '';
        position: absolute;
        background-image: url(https://cdn.aftenposten.no/ap/images/logo-ap.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        width: 110px;
        height: 24px;
        bottom: var(--space-8);
        right: 0;
      }
    }
  }

  .ab-feed-buttons-wrapper {
    display: none;
  }

  .ab-feed .ab-card .ab-pinned-indicator,
  .ab-feed .ab-feed-body {
    padding: 0;
    border: none;
  }

  .ab-feed .ab-card .ab-unread-indicator {
    height: 0;
  }

  .ab-feed .ab-feed-body .ab-card {
    width: unset;
    box-shadow: none;
    border-radius: 4px;
    padding: var(--space-4) var(--space-4) var(--space-6);

    display: flex;
    gap: var(--space-4);

    background-color: var(--card-bg-color);
    border: 1px solid var(--card-border-color);
    border-radius: 6px;

    @include respond-to(tablet) {
      align-items: center;
      border: 0;
      border-radius: 0;
      border-top: 1px solid var(--card-border-color);
      border-bottom: 1px solid var(--card-border-color);
      padding: var(--space-6) 0;
      gap: var(--space-8);
    }

    &.ab-classic-card.with-image {
      flex-direction: column;
      padding-top: 48px;

      @include respond-to(tablet) {
        flex-direction: row;
        padding-top: var(--space-6);
      }
    }
  }

  .ab-feed .ab-card.ab-classic-card {
    @include respond-to(tablet) {
      padding-top: var(--space-6);
    }
  }

  .ab-feed .ab-card.ab-classic-card .ab-close-button {
    @include respond-to(tablet) {
      top: var(--space-5);
    }
  }

  .ab-feed .ab-card.ab-classic-card .ab-image-area {
    height: 150px;
    width: 150px;
    flex-shrink: 0;
    position: static;
    padding: 0;

    > img {
      aspect-ratio: 1 / 1;
    }
  }

  .ab-feed .ab-card.ab-classic-card .ab-card-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .ab-feed .ab-feed-body .ab-card .ab-title {
    color: var(--card-title-color);
    font-family: var(--card-font-family);
    font-weight: 500;
    text-wrap: balance;
    font-size: 22px;
    line-height: 28px;
    padding: 0;
  }

  .ab-feed .ab-card.ab-classic-card:not(.with-image) .ab-title {
    margin: 0 var(--space-10) 0 0;
  }

  .ab-feed .ab-card .ab-description,
  .ab-feed .ab-card.ab-classic-card.with-image .ab-description {
    color: var(--card-description-color);
    padding: 0;
    margin-top: var(--space-2);
    font-family: inherit;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;

    @include respond-to(tablet) {
      margin-top: var(--space-4);
    }
  }

  .ab-feed .ab-card .ab-url-area {
    margin-top: var(--space-4);

    @include respond-to(tablet) {
      margin-top: var(--space-6);
    }

    > a {
      display: inline-block;
      background-color: var(--c-blue-50);
      color: #fff;
      border-radius: 20px;
      padding: 10px var(--space-4);
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
      line-height: 20px;

      &:hover {
        background-color: var(--c-blue-60);
        text-decoration: none;
      }

      &:active {
        background-color: var(--c-primary-04);
      }
    }
  }

  .ab-feed .ab-card .ab-close-button {
    opacity: 1;
    padding: var(--space-8);
    top: -24px;
    right: -24px;
    width: auto;
    height: auto;

    svg {
      fill: var(--c-primary-04);
      padding: var(--space-2);
      width: 16px;
      border-radius: 4px;
    }

    &:active svg {
      background-color: rgba(0, 7, 36, 0.3);
    }

    @include respond-to(tablet) {
      top: -8px;

      svg {
        min-width: 30px;
      }
    }

    @include respond-to(web) {
      padding: 0;
      top: 30px;
      right: 0;

      svg {
        padding: 0;
        padding: var(--space-2);
        min-width: 30px;
      }

      &:hover svg {
        background-color: rgba(0, 7, 36, 0.1);
      }
    }
  }

  // Variant override — captioned-image
  .ab-feed .ab-card.ab-captioned-image {
    --image-height: 230px;

    display: flex;
    flex-direction: column;

    @include respond-to(tablet) {
      flex-direction: row;
    }

    .ab-title {
      margin-right: 0;

      @include respond-to(tablet) {
        margin-right: var(--space-10);
      }
    }

    .ab-image-area {
      max-height: var(--image-height);
      height: auto;
      margin-top: var(--space-4);
      padding-top: var(--space-4);
      width: auto;

      @include respond-to(tablet) {
        padding-top: 0;
        margin-top: 0;
        height: auto;
        width: auto;
        min-width: 295px;
      }

      > img {
        aspect-ratio: 16 / 9;
        object-fit: contain;
        height: 100%;

        @include respond-to(tablet) {
          aspect-ratio: 4 / 3;
          width: 295px;
        }
      }
    }

    .ab-url-area {
      margin-top: var(--space-4);

      > a {
        display: inline-block;
        background-color: var(--c-blue-50);
        color: #fff;
        border-radius: 20px;
        padding: 10px var(--space-4);
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        line-height: 20px;

        &:hover {
          background-color: var(--c-blue-60);
          text-decoration: none;
        }

        &:active {
          background-color: var(--c-primary-04);
        }
      }
    }
  }

  // Variant override — image-only
  .ab-feed .ab-card.ab-image-only {
    --image-height: 250px;

    padding: 0;
    border: 0;

    .ab-close-button {
      display: none;
    }

    @include respond-to(tablet) {
      .ab-image-area {
        max-height: var(--image-height);
        height: auto;
        width: auto;
      }

      &::after {
        display: none;
      }
    }
  }
}
