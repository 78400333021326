$primaryDark: #000724;
$primaryRed: #b0002f;

.react-widget-wrapper.latest-news-widget {
  .items-container {
    height: auto;
    overflow: hidden;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  .items-container-overflow-wrapper {
    position: relative;
    &.scrolling {
      &::before {
        content: '';
        position: absolute;
        z-index: 2;
        width: 100%;
        pointer-events: none;
        height: 87px;
        top: 0;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0,
          #fffcfc 70%,
          #fffcfc 100%
        );
      }
    }
    &.scrollable {
      &::after {
        content: '';
        position: absolute;
        z-index: 2;
        width: 100%;
        pointer-events: none;
        height: 87px;
        bottom: -1px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          #fffcfc 100%
        );
      }
    }
    .items-container-overflow {
      overflow-y: scroll;
      max-height: 700px;
      scrollbar-width: none;
    }
  }

  .pagination-container-desktop {
    position: relative;
  }
  .items-wrapper {
    overflow: hidden;
    margin: var(--space-2) 0;
    min-height: 500px; // Prevents initial huge layout shift. Reduce this number if they want to show fewer teasers in the future.
    position: relative;
  }
  .pagination-button-wrapper {
    display: flex;
    justify-content: center;
    position: relative;

    &_mobile {
      background: unset;
      border: none;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      color: var(--c-blue-50);
      padding: var(--space-2) var(--space-4) 0 var(--space-4);
      border-top: 1px solid var(--c-shade-10);
      margin-top: var(--space-4);
      font: 500 14px/20px $fontGraphik;
      cursor: pointer;

      .pagination-button-label {
        cursor: pointer;
      }
    }

    .pagination-button {
      padding: 10px;
      border-radius: 30px;
      border: 1px solid var(--c-shade-10);
      background-color: white;
      width: fit-content;
      z-index: 1;

      svg {
        width: 18px;
      }

      &:hover {
        cursor: pointer;
      }

      &_mobile {
        padding: 8px;
        border-color: var(--c-blue-50);
        color: var(--c-blue-50);

        svg {
          display: flex;
          width: 14px;
        }
      }
    }

    &.desktop-down {
      bottom: 0;
    }

    &.desktop-up {
      position: relative;
      top: 0;
    }
    &.desktop-down,
    &.desktop-up {
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        user-select: none;
        &.down {
          bottom: 0;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            #fffcfc 100%
          );
        }
        &.up {
          top: 0;
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0) 0,
            #fffcfc 70%,
            #fffcfc 100%
          );
        }
      }
    }
  }
}
