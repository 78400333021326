.teaser-bundle {
  & .carousel {
    &::before {
      @include padded-separator(var(--c-shade-10));
    }
    & .carousel-content {
      display: flex !important;
      white-space: normal;
      padding: 0;
      scroll-padding: 0 0 0 var(--space-4);
      margin-bottom: var(--space-2);

      &::after {
        content: none;
      }
    }

    & .carousel-content .content-item {
      box-shadow: none;
      min-width: 85vw;
      border-right: var(--divider-line);
      scroll-margin-left: calc(-1 * var(--space-4));

      &:first-child {
        scroll-margin-left: 0;
        margin-left: var(--space-4);

        & .teaser.bundle-secondary {
          padding-left: 0;
        }
      }
      &:last-child {
        border-right: none;
      }
    }

    & .progress {
      display: flex !important;
      align-items: center;
      margin: 0 var(--space-4);

      $animOpts: 0.5s cubic-bezier(0.6, 0.02, 0.28, 1) both 1;
      &.rotate .arrow-right {
        @include spin(spinHalf, 0deg, 180deg);
        & {
          animation: spinHalf $animOpts;
        }
      }

      &.rotate-back .arrow-right {
        @include spin(spinHalfReverse, 180deg, 0deg);
        & {
          animation: spinHalfReverse $animOpts;
        }
      }
    }

    & .progress-bar {
      background-color: var(--c-shade-10);
    }

    & .counter {
      font-family: Graphik;
      font-size: 12px;
      line-height: 16px;
      color: var(--c-primary-03);
      padding: var(--space-2) var(--space-4);
      border: 2px solid var(--c-shade-10);
      box-sizing: border-box;
      border-radius: 20px;
      display: flex;
      min-width: 90px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    & .arrow-right {
      fill: var(--c-primary-03);
      margin-left: var(--space-2);
    }

    & .teaser.bundle-secondary {
      padding-top: 0;
    }

    & .teaser.bundle-secondary .container {
      border-top: none;
    }
  }
}

.teaser-bundle.breaking {
  & .carousel {
    & .progress-bar .bar {
      background-color: var(--c-dark);
    }

    & .counter {
      color: var(--c-dark);
    }

    & .arrow-right {
      fill: var(--c-dark);
    }
  }
}
.teaser-bundle.inverted {
  & .carousel {
    &::before {
      @include padded-separator(var(--c-tint-30));
    }
    & .carousel-content .content-item {
      border-color: var(--c-tint-30);
    }

    & .teaser.bundle-secondary .container {
      border-top: none;
    }

    & .progress-bar {
      background-color: var(--c-tint-30);
      & .bar {
        background-color: var(--c-light);
      }
    }

    & .counter {
      color: var(--c-light);
      border: 2px solid var(--c-tint-30);
    }

    & .arrow-right {
      fill: var(--c-light);
    }
  }
}
