.page-header {
  display: flex;
  flex-direction: column;
  margin-bottom: $gapStandard;
  h1 {
    @include title(30px, 34px);
    color: var(--c-dark);
  }
  @include respond-to(mobile-only) {
    margin: 0 1em;
  }
}

.page-description {
  margin-bottom: 1em;
  margin-top: 0;
  @include description(18px, 24px, var(--c-neutral-07));
}

.page-info-container {
  overflow-wrap: break-word;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-top: var(--divider-line);

  @include description(16px, 22px, var(--c-neutral-07));
  @include respond-to(mobile-only) {
    font-size: 0.688rem;
  }
}

.page-article-info-container {
  display: flex;
  align-items: center;
  margin-top: 1em;
}

.page-timestamp {
  display: flex;
}

.divider {
  border-left-width: 1px;
  border-left-style: solid;
  height: 1.2em;
  margin: 0 $gapStandard;
  opacity: 0.6;
}

.published {
  height: 1.2em;
  width: 1.2em;
  background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/clock.svg');
  background-size: cover;
  margin: auto 6px;
}
