$primaryDark: #000724;
$primaryRed: #b0002f;

@import '../components/Pagination/styles/collapsible-pagination';
@import '../components/LatestNewsTeaser/styles/latest-news-teaser';

.react-widget-wrapper.latest-news-widget {
  font-family: $fontGraphik;
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  padding-top: 0px;
  border: unset;
}
