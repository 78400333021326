@import '../../styles-desktop/widgets/benefits-widget';

.benefits-widget-container-mobile {
  display: block;

  .benefits-teaser {
    display: block;
    padding: var(--space-4) 0;
    margin: 0 var(--space-4);
    max-width: 500px;

    .benefits-teaser-content {
      .benefits-teaser-img-container {
        height: 343px;
        width: 100%;
      }

      .benefits-teaser-img {
        border-radius: 4px;
      }

      .benefits-teaser-title {
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.3px;
      }

      .benefits-teaser-button {
        align-self: flex-start;
        padding: 4px 8px;
      }

      .benefits-teaser-button-text {
        margin: 0 auto;
        font-weight: bold;
        line-height: 18px;
        font-size: 14px;
      }
    }
  }
}

.benefits-widget-wrapper-mobile {
  .react-widget-wrapper__link {
    gap: 16px;
    justify-content: flex-end;
    position: relative;
    .react-widget-wrapper__link-arrow {
      margin: 0;
    }
  }
  .react-widget-wrapper__link::after {
    display: block;
    content: url('https://cdn.aftenposten.no/benefits/a-kortet-logo.svg');
    position: absolute;
    left: 0;
    width: 70px;
    top: 50%;
    transform: translateY(-50%);
  }
}
