.stripe-wrapper {
  display: none;
  max-height: 150px;
}

.imp-no-session,
.imp-logged-in {
  &:not(.imp-has-product) {
    .stripe-wrapper {
      display: block !important;

      .non-subscriber-stripe {
        display: block !important;
      }
    }
  }
}
