// ------------------------------------
// BREAKPOINTS
// ------------------------------------
@mixin respond-to($device) {
  @if $device == mobile-only {
    @media (max-width: $grid-width-tablet) {
      @content;
    }
  } @else if $device == tablet {
    @media (min-width: $grid-width-tablet) {
      @content;
    }
  } @else if $device == tablet-only {
    @media (min-width: $grid-width-tablet) and (max-width: $grid-width-web) {
      @content;
    }
  } @else if $device == web {
    @media (min-width: $grid-width-web) {
      @content;
    }
  } @else if $device == web-wide {
    @media (min-width: $grid-width-web-wide) {
      @content;
    }
  }
}
