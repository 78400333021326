.newsletter-widget {
  .react-widget-wrapper {
    &__header-left {
      gap: var(--space-2);

      &::before {
        width: 24px;
        height: 22px;
        content: '';
        background-image: url('https://cdn.aftenposten.no/vink/images/icons/email.svg');
        background-size: 24px 22px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    &__link-arrow {
      padding: var(--space-2);
      border-radius: 100%;
      background-color: var(--c-text-link);
      position: relative;
      width: 28px;
      height: 28px;
      &::after {
        display: inline-block;
        content: '';
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path stroke="%23FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1.438 8h13.124M8.438 14.125 14.561 8 8.439 1.875"/></svg>');
        background-repeat: no-repeat;
        background-size: 12px 12px;
        width: 12px;
        height: 12px;
      }
      img {
        display: none;
      }
    }
  }

  &__ol {
    list-style: none;
    display: flex;
    gap: 50px;
  }
  &__li {
    position: relative;
    max-width: 294.33px;
    position: relative;
    flex: 1;
    margin: var(--space-4) 0;
    display: flex;
    gap: var(--space-4);
    .newsletter-widget__content-wrapper {
      display: flex;
      flex-direction: column;
      .display-name {
        font-family: $fontPublicoHeadline;
        font-size: 22px;
        line-height: 26px;
        font-weight: 500;
        letter-spacing: -0.3px;
        margin-bottom: 10px;
      }

      .description,
      .text,
      .text-error {
        font-family: Graphik;
        font-weight: 400;
        margin: 0;
      }
      .description {
        line-height: 20px;
        font-size: 14px;
        color: #333333;
      }

      .text {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        font-weight: 400;

        .subscribe {
          color: #333333;
        }

        .subscribe {
          font-weight: 400;
          font-size: 14px;
        }

        .small {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          color: #000c25;
        }
      }
    }

    &:not(:first-child)::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 1px;
      background: rgba(0, 7, 36, 0.1);
      flex-shrink: 0;
      left: -25px;
    }
  }
  .newsletter-widget__img-wrapper {
    display: flex;
    flex-shrink: 0;
    width: 107px;
    height: 140px;

    .newsletter-widget__img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
  }
  .display-name {
    margin-bottom: var(--space-2);
  }
  &__footer {
    display: flex;
    align-items: center;
    margin-top: auto;
    .text-error {
      color: #eb1a46;
      font-size: 12px;
      grid-column: 2;
    }
    .text-error.error-needs-sub {
      grid-column: 1;
    }
    .text.text-subscription {
      grid-column: 1/3;
    }
  }
  &__link {
    text-decoration: underline;
    &:focus {
      outline: auto;
      outline-color: #00259d;
    }
  }
  &__subscribe-container {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 6px;
    align-items: center;
  }

  .toggle-btn {
    display: flex;
    align-items: center;
    height: 24px;
    width: 24px;
    margin-top: 3px;
    border: 1.5px solid #00259d;
    border-radius: 4px;

    &__input {
      position: absolute;
      opacity: 0;

      &:checked + .toggle-btn__circle {
        background-color: #00259d;

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.53 8.043a.75.75 0 0 1 0 1.061l-9 9a.75.75 0 0 1-1.06 0l-4-4.005a.75.75 0 1 1 1.06-1.06L10 16.513l8.47-8.47a.75.75 0 0 1 1.06 0Z" fill="%23fff"/></svg>');
        }
      }
      &:focus-visible + .toggle-btn__circle {
        outline: auto 2px;
        outline-offset: 4px;
        outline-color: #00259d;
      }

      &:checked:focus-visible + .toggle-btn__circle {
        outline-color: #00259d;
      }
    }

    &__circle {
      position: relative;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
  .newsletter-widget__link {
    color: #0533ff;
  }
}

.newsletter-variant-B {
  .newsletter-widget {
    &__li {
      background-color: #e8f1fa;
      gap: var(--space-4);
      max-width: 312px;
      .newsletter-widget__content-wrapper {
        gap: 8px;
        padding: var(--space-4) 0;
      }
      .newsletter-widget__img-wrapper {
        height: 100%;
        padding: var(--space-4) 0 var(--space-4) var(--space-4);
      }

      &::after {
        display: none;
      }
      .small {
        color: #656565;
      }
    }
    &__ol {
      gap: var(--space-6);
    }
    &__info {
      padding-right: var(--space-4);
    }

    &__footer {
      margin-top: auto;
      padding-right: 13px;
    }
  }
}
