.teaser {
  .kicker {
    margin-bottom: var(--space-1);
    @include label-1(var(--c-dark), 400);
    white-space: pre-line;
  }
  &.inverted.breaking .kicker {
    color: var(--c-light);
  }
  &.inverted .kicker,
  &.feature .kicker {
    color: var(--c-light);
  }

  &.breaking .kicker {
    @include label-1(var(--c-breaking-light), 600);
  }

  &.magazine .kicker {
    text-align: center;
  }
}
