@import './games-mobile';

.games-widget {
  --transition-attributes: 0.3s ease;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0;
  padding: var(--space-4) 0 var(--space-6);

  li {
    display: flex;

    &:not(:last-child) {
      &:after {
        width: 1px;
        height: 100%;
        margin: 0 var(--space-6);
      }
    }
  }

  .game {
    flex: 1;
    transition: background-color var(--transition-attributes);
    padding: var(--space-2) 0;

    .description {
      font-size: 12px;
      line-height: 16px;
    }

    .illustration-container {
      width: 64px;
      height: 64px;
    }

    &:hover {
      background-color: rgba(255, 203, 9, 0.16);
    }

    &:active {
      background-color: rgba(255, 203, 9, 0.3);
    }
  }
}
