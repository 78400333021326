$imageWrapperGrid: 118px;
$imageWrapperGridSmall: 94px;
$imageWrapperGridDesktop: 88px;
$imageWrapperGridDesktopLarge: 128px;

.teaser.illustration {
  .illustration-container {
    display: grid;
    grid-template-columns: 1fr $imageWrapperGrid;
    row-gap: var(--space-2);
    height: 100%;
    .text {
      grid-column: 1;
    }
    .author-image-wrapper--no-grayscale {
      grid-row: 1/3;
      grid-column: 2;
      align-self: end;
    }
    .illustration-byline-info {
      align-self: end;
      grid-row: auto;
      grid-column: 1;
    }
  }
  &.small {
    .illustration-container {
      grid-template-columns: 1fr $imageWrapperGridSmall;
    }
  }
}

//Desktop, suggestion using media querys
@media (min-width: 765px) {
  .teaser.illustration {
    .illustration-container {
      grid-template-columns: 1fr $imageWrapperGridDesktop;
      .text {
        grid-column: 1 / 3;
      }
      .illustration-byline-info {
        align-self: end;
      }
      .author-image-wrapper--no-grayscale {
        grid-column: 2;
        grid-row: 2;
        justify-self: end;
        align-self: end;
      }
    }
    &.large {
      .illustration-container {
        grid-template-columns: 1fr $imageWrapperGridDesktopLarge;
        .text {
          grid-column: 1;
        }
        .author-image-wrapper--no-grayscale {
          grid-row: 1/3;
          grid-column: 2;
          align-self: end;
        }
        .illustration-byline-info {
          align-self: end;
        }
      }
    }
  }
}
