$mediumTeaserHeight: 467px;
$featureTeaserWidth: 100%;
$largeTeaserHeight: 484px;
$largeTeaserHeightMobile: 150vw;
$mediumTeaserHeightMobile: 100vw;

.teaser.feature {
  padding: 0;
}
.teaser.feature {
  text-align: center;
  .information {
    justify-content: center;
  }
}
.teaser.feature.medium {
  .teaser-link {
    height: $mediumTeaserHeightMobile;
  }
}
.teaser.feature.breaking {
  text-align: left;
  .information {
    justify-content: flex-start;
  }
}
.teaser.feature.large {
  .teaser-link {
    height: $largeTeaserHeightMobile;
  }
}
.teaser.feature .feature-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;

  .img-background {
    position: absolute;
    background-position: center;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .container-linear-gradient {
    height: 20%;
    background: var(--c-linear-gradient);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 var(--space-4);
  }
  .z-index {
    z-index: 0;
  }

  .container-darken {
    background: var(--c-shade-66);
    display: flex;
    flex-direction: column;
    padding: 0 var(--space-4) var(--space-8);
  }
}

.teaser .teaser-link {
  display: block;
}
.teaser.feature {
  border-radius: 4px;
  .teaser-link {
    overflow: hidden;
  }
}
.teaser.feature.magazine {
  .container-darken {
    align-items: center;
  }
}
@include series-feature($mobile: true);

//Desktop, suggestion using media querys
@media (min-width: 765px) {
  @include series-feature($mobile: false);
  .teaser.feature {
    border-radius: 4px;
    overflow: hidden;
    .information {
      justify-content: flex-start;
    }
    .title {
      text-align: left;
      max-width: 580px;
    }
  }
  .teaser.feature.magazine {
    .information {
      justify-content: center;
    }
  }
  .teaser.feature.large .teaser-link {
    height: $largeTeaserHeight;
    width: $featureTeaserWidth;
  }
}
