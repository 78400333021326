.mobile-login-widget {
  width: 100%;
  background-color: #ffcc9a;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-login-widget-h5t2 {
  font-family: $fontGraphik;
  width: 100%;
  background-color: #f8f8f8;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

.mobile-login-widget-h4t1 {
  font-family: $fontGraphik;
  width: 100%;
  background-color: #f8f8f8;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 20px;
}

.mobile-login-widget-ap-logo {
  width: 25%;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 10px;
}

.mobile-login-widget-image {
  margin: 0 auto;
}

.small-mobile-login-widget-image {
  width: 50%;
}

.large-mobile-login-widget-image {
  width: 70%;
}

.mobile-login-widget-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: var(--c-dark);
  align-self: center;
  margin: 0 auto;
  padding: 0px 50px;
  text-align: center;
}

.mobile-login-widget-body-text {
  font-size: 20px;
  align-self: center;
  margin: 0 auto;
  padding: 0px 50px;
}

.mobile-login-widget-list {
  margin: 0 auto;
  padding: 0px 50px;
}

.mobile-login-widget-list > li {
  @include description(18px, 18px, var(--c-dark));
  list-style-type: disc;
}

.mobile-login-widget-button {
  @include description(18px, 18px, var(--c-dark));
  font-weight: bold;
  background-color: #ffffff;
  border-radius: 2em;
  border: none;
  box-shadow: 1px 1px 10px black;
  height: 50px;
  width: 175px;
  margin: 0 auto;
  padding: 0px 50px;
  margin-bottom: 20px;
  cursor: pointer;
}

.mobile-login-widget-h5t2-button,
.mobile-login-widget-h4t1-button {
  @include description(18px, 18px, #ffffff);
  font-family: $fontGraphikSemibold;
  line-height: 1.4em;
  font-weight: 600;
  border-radius: 2em;
  border: none;
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 4px 4px 5px);
  height: 45px;
  width: 80%;
  margin: 0 auto;
  padding: 0px 50px;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: #002fc4;
}

.mobile-login-widget-h4t1-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-login-widget-h4t1-button-subtext {
  text-align: center;
  font-size: 12px;
}
