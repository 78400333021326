.hytte-outer-container {
  background-color: #f7f7f7;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 99;

  img {
    height: 40px;
  }
  .hytte-inner-container {
    padding: 14px 10px 10px 10px;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .hytte-subscribe-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 24px;
    background: #00259d;
    border-radius: 100px;
    border: none;
    outline: none;
    font-family: 'Graphik';
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    color: white;
  }

  .hytte-subscribe-button:hover {
    background: #001b74;
    color: white;
  }

  .hytte-subscribe-button:active {
    background: #000724;
    color: white;
  }

  .hytte-text-links {
    display: flex;
    align-items: center;
    font-family: 'Graphik';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-left: auto;
    gap: 2em;
  }

  .hytte-text-links > a {
    color: #00259d;

    &:hover {
      text-decoration: underline;
    }
  }

  .hytte-order-button {
    margin-left: 2em;
  }
  @media (max-width: 767px) {
    .hytte-text-links {
      display: none;
    }
  }
}
