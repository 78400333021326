.teaser.multimedia .description {
  @include description();
  margin-bottom: var(--space-2);
  margin-block-start: 0px;
}

@media (min-width: 765px) {
  .teaser.multimedia .description {
    @include bundle-summary();
  }
}
