body {
  font-family:
    Publico Headline,
    Georgia,
    Times,
    'Times New Roman',
    serif;
  color: var(--c-dark);
  margin: 0;

  &.ab-sub-colors {
    background: var(--c-light);
  }
  overflow-x: hidden;
}

#inner-container {
  background-color: var(--c-background);
}

.ch-header-sub,
.widget-livefeed .see-more-button {
  display: none;
}

//overrides could also be moved to be teaser specific?
h2,
h3,
h4,
a {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
