.login-widget {
  width: 100%;
  display: grid;
  grid-template-columns: 33% 66%;
  background-color: #ffcc9a;
  height: auto;
}

.login-widget-h5t2 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: #f8f8f8;
  height: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.login-widget-h4t1 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: #f8f8f8;
  height: auto;
  padding-top: 25px;
  padding-bottom: 25px;
}

.login-widget-ap-logo {
  width: 120px;
  margin-bottom: 20px;
}

.login-widget-image {
  height: 160px;
  margin: 0 auto;
}

.login-widget-title {
  font-family: $fontGraphik;
  font-style: normal;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.3px;
  color: var(--c-dark);
  align-self: center;
}

.login-widget-left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 100px;
}

.login-widget-right {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 100px;
}

.login-widget-h4t1 > .login-widget-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: $fontGraphik;
  font-size: 20px;
  max-width: 500px;

  img {
    margin-left: 40px;
    height: 220px;
  }
}

.login-widget-h4t1 > .login-widget-left {
  padding-top: 20px;
}

.login-widget-body {
  display: grid;
  grid-template-columns: 50% 50%;
  font-family: $fontGraphik;
  font-size: 20px;
}

.login-widget-list > li {
  @include description(18px, 18px, var(--c-dark));
  list-style-type: disc;
}

.login-widget-button {
  @include description(18px, 18px, var(--c-dark));
  font-weight: bold;
  background-color: #ffffff;
  border-radius: 2em;
  border: none;
  box-shadow: 1px 1px 10px black;
  height: 50px;
  width: 175px;
  cursor: pointer;
}

.login-widget-h5t2-button,
.login-widget-h4t1-button {
  @include description(18px, 18px, #ffffff);
  font-family: $fontGraphikSemibold;
  line-height: 1.4em;
  font-weight: 600;
  border-radius: 2em;
  border: none;
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 4px 4px 5px);
  height: 50px;
  width: 230px;
  cursor: pointer;
  background-color: #002fc4;
}

.login-widget-h4t1-button {
  margin-top: 10px;
  width: 250px;
}

.login-widget-h4t1-button-wrapper {
  display: flex;
  flex-direction: column;
  align-self: start;
  align-items: center;
  gap: 20px;
}

.login-widget-h4t1-button-subtext {
  font-family: $fontGraphik;
  font-size: 14px;
}
