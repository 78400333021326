$cdnUrl: 'https://cdn.aftenposten.no/ap/fonts/';

@mixin cdn-font-face($family, $path, $filename, $weight: null, $style: null) {
  @font-face {
    font-family: $family;
    src: url($cdnUrl + $path + $filename + '.woff2') format('woff2'),
      url($cdnUrl + $path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin publicoHeadline($name, $weight, $style: null) {
  @include cdn-font-face(
    'Publico Headline',
    'pbh/',
    'PublicoHeadline' + $name,
    $weight,
    $style
  );
}

@mixin publicoText($name, $weight, $style: null) {
  @include cdn-font-face(
    'Publico' + $name,
    'pbt/',
    'PublicoText' + $name,
    $weight,
    $style
  );
}

@mixin produkt($name, $weight) {
  @include cdn-font-face('Produkt', 'produkt/', 'Produkt' + $name, $weight);
}

@mixin produktXX($name, $weight) {
  @include cdn-font-face(
    'ProduktXXCond',
    'produkt/',
    'ProduktXXCond' + $name,
    $weight
  );
}

@mixin graphikXCond($name, $weight) {
  @include cdn-font-face(
    'GraphikXCond',
    'graphik/',
    'GraphikXCond' + $name,
    $weight
  );
}

@mixin graphik($name, $weight) {
  @include cdn-font-face('Graphik', 'graphik/', 'Graphik' + $name, $weight);
}

@mixin inter($name, $weight) {
  @include cdn-font-face('Inter', 'inter/', 'Inter' + $name, $weight);
}

@include publicoHeadline('-Extrabold', 800);
@include publicoHeadline('-Bold', 700);
@include publicoHeadline('-Medium', 500);
@include publicoHeadline('', 'normal');
@include publicoHeadline('-Light', 300);
@include publicoHeadline('-Italic', 'normal', 'italic');
@include publicoHeadline('-BoldItalic', 700, 'italic');

@include publicoText('', 'normal');
@include publicoText('-Bold', 'bold', normal);
@include publicoText('-Italic', 'normal', 'italic');

@include graphik('-Light', 300);
@include graphik('', 'normal');
@include graphik('-Medium', 500);
@include graphik('-Semibold', 600);
@include graphik('-Bold', 700);
@include graphikXCond('-Semibold', 600);

@include produkt('-Semibold', 600);
@include produkt('-Black', normal);
@include produkt('-Bold', 600);
@include produkt('-Italic', 300);
@include produkt('-Medium', 300);
@include produkt('', normal);
@include produktXX('-Semibold', 600);
@include produktXX('', normal);

@include inter('-Bold', 700);
