@mixin a-logo(
  $h: 24px,
  $w: 24px,
  $l: 14px,
  $t: -1px,
  $pos: absolute,
  $colorSuffix: ''
) {
  content: '';
  display: block;
  background-image: url('https://cdn.aftenposten.no/ap/images/illustrations/a-logo-cropped#{$colorSuffix}.svg');
  height: $h;
  width: $w;
  background-repeat: no-repeat;
  background-size: contain;
  position: $pos;
  left: $l;
  top: $t;
}

@mixin a-logo-widget() {
  @include a-logo();
  display: inline-block;
  position: relative;
  vertical-align: text-bottom;
  padding-right: 8px;
  margin-right: 19px;
  border-right: 1px solid #979797;
  width: 20px;
  height: 21px;
  left: 0;
}
