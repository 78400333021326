.kort-forklart {
  .summary {
    @include expandable-summary();
    margin: 0;
    padding: 0;
    border-bottom: none;

    p:not(:last-child) {
      margin-bottom: var(--space-2);
    }
  }

  &.opinion.with-byline .summary {
    margin-bottom: var(--space-5);
  }

  &.large,
  &.medium {
    .title {
      margin-bottom: var(--space-2);
    }
    .teaser-image-wrapper {
      margin-bottom: var(--space-2);
    }
    .teaser-image-wrapper .teaser-image {
      position: relative;
    }
  }
  &.large.feature {
    width: auto;
    height: auto;
  }
  &.inverted {
    .summary {
      color: var(--c-neutral-03);
      & ul li::before {
        background-color: var(--c-neutral-03);
      }
      & summary {
        & li::after,
        & p::after {
          color: var(--c-semantic-info-tint-3);
        }
      }
    }
  }

  &.feature,
  &.magazine,
  &.history-magazine {
    .information {
      justify-content: space-between;
      padding-bottom: var(--space-2);
      border-bottom: var(--divider-line);
      margin-top: var(--space-4);
      .context-label {
        color: var(--c-dark);
      }
      .relevant-tag {
        border-color: var(--c-shade-10);
        color: var(--c-neutral-07);
      }
      .comments-count {
        top: 0;
        right: 0;
      }
    }
    .title,
    .kicker {
      color: var(--c-dark);
      text-align: initial;
    }
    &.large .title {
      margin-bottom: var(--space-2);
    }
    .summary {
      text-align: start;
    }
  }
}
