@import 'node_modules/@schibsted/data-controller/dist/styles';

.sch-datacontroller {
  z-index: 1001; // higher than ads cog wheel button z-index: 1000
  line-height: initial;
  background: #f8f6f5;
  color: #767676;
  padding: 24px 0;
  border-top: 1px solid #c6c4c4;

  a#{&}__read-more {
    &,
    &:visited,
    &:focus,
    &:hover {
      color: #0063fb;
    }
  }

  &__content {
    justify-content: center;
  }

  &__btn-close {
    display: none;
  }

  @media screen and (max-width: 768px) {
    &__logo,
    &--optional-mobile {
      display: none;
    }
  }
}

#data-controller-permanent {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
}
