@import '../../styles-desktop/widgets/newsletter-widget';

.newsletter-widget.mobile {
  .react-widget-wrapper {
    &__link {
      gap: 4px;
    }

    &__link-arrow {
      background-color: transparent;
      padding: 0;
      width: auto;
      height: auto;
      &::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path stroke="%2300259D"  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1.438 8h13.124M8.438 14.125 14.561 8 8.439 1.875"/></svg>');
      }
    }
  }
  .newsletter-widget {
    &__ol {
      flex-direction: column;
      gap: 32px;
      padding: 0;
    }
    &__li {
      max-width: max-content;
      margin: 0 var(--space-4);

      .newsletter-widget__img-wrapper {
        width: 120px;
        height: 120px;
      }
      .display-name {
        font-family: $fontPublicoHeadline;
        font-size: 22px;
        line-height: 26px;
        font-weight: 500;
        letter-spacing: -0.3px;
        margin-bottom: 8px;
      }
      .description,
      .text {
        font-family: Graphik;
        font-weight: 400;
      }
      &:not(:last-child)::before {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        background: rgba(0, 7, 36, 0.1);
        flex-shrink: 0;
        bottom: -16px;
      }
      &::after {
        display: none;
      }
    }
  }
}

.newsletter-widget.mobile.newsletter-variant-B {
  .newsletter-widget {
    &__ol {
      gap: var(--space-4);
    }
    &__li {
      background-color: #e8f1fa;
      .display-name {
        margin-bottom: 4px;
      }
      .description {
        color: #4f4f4f;
      }
      .small {
        color: #656565;
      }
      .newsletter-widget__img-wrapper {
        min-height: 120px;
        height: auto;
        width: 95px;
      }
      &::before {
        display: none;
      }
    }
  }
}
