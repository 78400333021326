.teaser {
  &.memorial {
    padding: var(--space-4);
    .container {
      display: flex;
      gap: var(--space-2);
      align-items: center;
      justify-content: space-between;
      .memorial-text {
        .memorial-label {
          @include label-2(var(--c-dark), 600);
          text-transform: capitalize;
          margin: 0px 0px 4px 0px;
        }
      }
      .teaser-image {
        width: 94px;
        height: 94px;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

//Desktop, suggestion using media querys
@media (min-width: 765px) {
  .teaser {
    &.memorial {
      padding: 0;
      .container {
        .teaser-image {
          width: 135px;
          height: 135px;
        }
      }
    }
  }
  .double {
    .teaser {
      &.memorial {
        .container {
          flex-direction: column-reverse;
          .memorial-text {
            text-align: center;
          }
        }
      }
    }
  }
  .teaser.memorial.medium .container {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;

    .memorial-text {
      text-align: center;
    }
  }

  .teaser.memorial.small .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    max-height: 240px;

    .memorial-text {
      text-align: center;
    }
  }
}
