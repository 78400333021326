.teaser.multimedia {
  position: relative;
  display: flex;
  &.medium,
  &.large {
    flex-direction: column;
  }
  .integration {
    margin-bottom: var(--space-4);
    width: 100%;
    overflow: hidden;
    border-radius: var(--space-1);
    margin-bottom: var(--space-4);
  }
  &.large {
    .integration {
      width: calc(100vw - 32px);
    }
  }
  .container {
    .wrapper {
      margin-bottom: var(--space-4);
      .description {
        color: var(--c-neutral-09);
      }
    }
  }

  &.small {
    .container {
      display: block;
    }
  }
}

@media (min-width: 765px) {
  .teaser.multimedia {
    padding: 0;

    &.large {
      .integration {
        width: auto;
      }
    }
    .teaser-link {
      position: relative;
    }

    .container .wrapper {
      margin-bottom: 0;
    }

    &.small {
      .teaser-image-wrapper,
      .integration {
        width: 209px;
        height: 139px;
        flex-shrink: 0;
      }
    }

    &.medium {
      flex-direction: column;

      .teaser-image-wrapper {
        object-fit: cover;
      }

      .integration {
        height: 100%;
        width: 100%;
      }

      .container {
        padding: 0;
      }
    }

    &.large {
      flex-direction: column;
    }
  }

  .block.medium.beta .teaser.multimedia {
    &.medium {
      .integration {
        min-height: 196px;
      }
    }
  }

  .full-width-top .teaser.multimedia {
    flex-direction: row-reverse;
  }
}

.teaser.multimedia,
.teaser.withMultimedia {
  .integration .mm-svp-front-video[data-mm-object-fit='cover'] {
    .jwplayer.jw-stretch-uniform .jw-media video {
      object-fit: cover;
    }
  }

  .integration .mm-svp-front-video[data-mm-object-fit='contain'] {
    .jwplayer.jw-stretch-uniform .jw-media video {
      object-fit: contain;
    }
  }
}
