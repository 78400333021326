@import 'styles-common/_variables';
$colorWidgetBorder: #e0e4ea;
$colorBrightBlue: #005bff;

.carousel {
  position: relative;
  padding-bottom: 16px;
  padding-top: 8px;

  .carousel-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    max-width: 1000px;

    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
    .size-helper-grid {
      width: 316px;
    }

    .content-item {
      box-shadow: 0 0 7px #888888;
      display: flex;
      flex-direction: column;
      font-family: $fontRobotoLight;
      font-size: 18px;
      line-height: 1.28em;
    }

    .column {
      .feature-article {
        .article-image {
          width: 316px;
        }
      }

      .size-helper-grid {
        width: 316px;
      }
    }

    &::after {
      content: '';
      flex: 0 0 64px;
    }
  }

  .see-more-button {
    display: block !important;
    position: absolute;
    top: 0;
    bottom: 26px;
    width: 6em;
    right: 0;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 90%
    );

    button {
      position: absolute;
      top: 35%;
      right: 0;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      box-shadow: none;
      border: none;
      color: #fff;
      background-color: $colorBrightBlue;
      &:hover {
        cursor: pointer;
        background-color: adjust-color($colorBrightBlue, $lightness: -5%);
      }
    }
    &.rotate {
      button {
        @include spin(spinHalf, 0deg, 180deg);
        & {
          animation: spinHalf 0.5s cubic-bezier(0.6, 0.02, 0.28, 1) both;
          animation-iteration-count: 1;
        }
      }
    }
    &.rotate-back {
      button {
        @include spin(spinHalfReverse, 180deg, 0deg);
        & {
          animation: spinHalfReverse 0.5s cubic-bezier(0.6, 0.02, 0.28, 1) both;
          animation-iteration-count: 1;
        }
      }
    }

    .i-right-arrow {
      display: inline-block;
      height: 16px;
      width: 16px;
      position: absolute;
      top: 12px;
      right: 11px;
      background: url('https://cdn.aftenposten.no/ap/images/functional_icons/bundle_arrows/arrow_forward_inverted_24dp.svg')
        left center no-repeat;
    }
  }

  .progress-bar {
    height: 2px;
    width: 100%;
    position: relative;
    background-color: $colorWidgetBorder;

    .bar {
      position: absolute;
      left: 0;
      height: 2px;
      background-color: $colorBrightBlue;
    }
  }
}
