$bundleElementBase: 209px;

.teaser-bundle.desktop {
  // baseline styles
  &.inverted {
    &:has(.bundle-description) {
      .bundle-title {
        border-bottom: none;
      }
    }
  }

  .teaser.bundle-main.desktop {
    padding: 0 var(--space-6) 0 0;
    border-right: var(--divider-line);
    margin: var(--space-4) 0;

    &:not(.withMultimedia) .teaser-link,
    &.withMultimedia .content-wrapper {
      display: flex;
      width: 725px;
      justify-content: space-between;
    }

    .teaser-image-wrapper {
      flex-shrink: 0;
      margin-bottom: 0;
    }
    .summary {
      @include summary-block(49px, $bundleElementBase);
      h1 {
        display: none;
      }
    }

    &.inverted {
      .summary {
        color: var(--c-light);
      }

      .teaser-section-title {
        .relevant-tag {
          color: var(--c-neutral-05);
        }
        .section {
          color: var(--c-light);
        }
      }
    }

    .teaser-section-title {
      .relevant-tag {
        @include label-2(var(--c-neutral-06));
      }
      .section {
        @include label-2(var(--c-dark), 600);
      }
    }

    .text-container-medium {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 49px;
    }

    &.with-byline-image .text-container-medium {
      margin-right: var(--space-4);
    }

    .text-container-small {
      display: flex;
      & > div {
        position: relative;
        padding-top: var(--space-2);
      }
      .byline {
        display: flex;
        flex-direction: column-reverse;
        flex-shrink: 0;
        justify-content: end;
        align-items: flex-start;
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
        margin-right: var(--space-6);
      }
      .author-image-wrapper {
        margin: 0 var(--space-2) var(--space-1) 0;
      }

      &.with-summary .summary {
        padding-right: var(--space-4);
        margin-right: var(--space-4);
        border-right: var(--divider-line);
        &:last-of-type {
          border-right: none;
          padding-right: 0;
          margin-right: 0;
        }
        width: calc(295px + var(--space-6) + 2 * var(--space-4));
      }
    }

    &:not(.inverted) {
      &.feature,
      &.magazine,
      &.history-magazine {
        .kicker,
        .title {
          color: var(--c-dark);
        }
      }
    }
  }

  // large bundle overrides
  .large {
    .bundle-main .title {
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -0.5px;
    }

    &.move-title-right {
      .teaser.bundle-main.desktop {
        border-right: none;
        .byline {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
        .title {
          @include line-clamp(4);
        }

        .teaser-section-title + .title,
        .kicker + .title {
          @include line-clamp(3);
        }
      }
      .text-container-large {
        position: absolute;
        z-index: 2;
        left: 513px;
        width: 463px;
      }
      &:not(.single-secondary) {
        .teaser.bundle-main.desktop.with-summary {
          .text-container-large {
            height: 327px;
          }
          .text-bottom {
            position: absolute;
            bottom: var(--space-4);
          }
        }
      }
    }
    &.expand-vertical-space {
      .teaser.bundle-main.desktop {
        margin-bottom: var(--space-8);
      }
    }
    .teaser.bundle-main.desktop {
      &:not(.withMultimedia) .teaser-link {
        justify-content: space-between;
        flex-direction: column;
        width: 467px;
        height: 100%;
      }
      .text-top {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
      .teaser-image-wrapper {
        height: 311px;
      }
      .text-container-large {
        padding-bottom: var(--space-4);
      }

      &.with-byline-image:not(.with-summary) {
        .text-top {
          margin-right: var(--space-4);
        }
        .text-container-large {
          display: flex;
          justify-content: space-between;
          .byline {
            display: flex;
            flex-direction: column-reverse;
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
            align-items: flex-end;
            flex-shrink: 0;
            max-width: 123px;
            justify-content: end;
          }
          .author-name,
          .author-title {
            text-align: end;
            margin-right: 0;
          }

          .author-image-wrapper {
            margin-left: 0;
          }
        }
      }

      &.with-summary {
        .title {
          margin-bottom: var(--space-2);
        }
        .summary {
          padding-left: 0;
          border-left: none;
          width: 100%;
        }
      }
    }
  }

  // medium bundle overrides
  .medium {
    .teaser.bundle-main.desktop {
      &:not(.withMultimedia) .teaser-link,
      &.withMultimedia .content-wrapper {
        height: 254px;
      }
      .teaser-image-wrapper,
      &.withMultimedia .integration {
        width: 381px;
      }

      &.feature,
      &.magazine,
      &.history-magazine {
        .text-container-medium {
          align-items: center;
          justify-content: center;
          width: 100%;
        }
        .title {
          font-size: 40px;
          line-height: 40px;
        }
        .title,
        .kicker,
        .teaser-section-title {
          text-align: center;
        }

        &:not(.withMultimedia) .teaser-link,
        &.withMultimedia .content-wrapper {
          height: 381px;
        }
      }
    }

    .teaser.bundle-main.desktop.withMultimedia {
      .integration {
        width: 100%;
        flex-shrink: 1;
      }
    }
  }

  // small bundle overrides
  .small {
    .teaser.bundle-main.desktop {
      padding-top: 0;
      .teaser-image-wrapper {
        width: $bundleElementBase;
        padding-top: var(--space-2);
        height: calc(150px + var(--space-2));
      }
      .title {
        font-size: 22px;
        line-height: 26px;
        margin-right: var(--space-8);
      }
      .teaser-image {
        height: 150px;
        object-fit: cover;
      }
      &.with-byline-image .title {
        @include line-clamp(3);
      }
      &.opinion {
        .summary {
          width: calc(450px + var(--space-6) + 2 * var(--space-4));
        }
      }
    }
  }

  .medium,
  .small {
    .teaser.bundle-main.desktop .title + .summary {
      padding-left: 0;
      width: 100%;
    }
  }

  // main article spreading on the whole row because of summary present: overrides
  .top-container.full {
    .teaser.bundle-main.desktop {
      width: 100%;
      border-right: none;
      padding-right: 0;
      display: flex;
      justify-content: space-between;
      &:not(.withMultimedia) .teaser-link {
        width: 100%;
      }
    }
  }
}
