.front-video-top-wrapper {
  overflow: hidden;
  padding: 16px;
  margin-bottom: 8px;
  background-color: var(--c-light);

  .frontvideo-svp-player {
    position: relative;
    width: 100%;
    max-width: 100vw;
    height: 100%;

    &.has-aspect-ratio .jwplayer:not(.jw-flag-fullscreen) video.jw-video {
      object-fit: cover;
    }

    .jwplayer.jw-flag-fullscreen video.jw-video {
      object-fit: contain;
    }

    .jwplayer {
      position: static;
    }

    &:not(.has-aspect-ratio) {
      .jwplayer {
        background-color: var(--c-dark);
      }
    }

    .jw-wrapper,
    .jw-preview.jw-reset,
    .jw-controls.jw-reset {
      background-color: transparent;
    }

    &:not(.jw-state-idle) {
      .jw-controls-backdrop.jw-reset {
        background: linear-gradient(
            to bottom,
            transparent,
            rgb(0 7 36 / 40%) 77%,
            rgb(2 7 36 / 40%) 100%
          )
          100% 100% / 100% 240px no-repeat transparent;
      }
    }

    /* Play/pause button in control bar */
    div.jw-icon.jw-icon-inline.jw-icon-playback[role='button'] {
      display: flex;
    }

    /* Button appearing in the middle of the video frame */
    div.jw-icon.jw-icon-display.jw-reset[role='button'] {
      opacity: 0.8;
      background-color: var(--c-dark);
      border-radius: 100%;
      color: var(--c-light);
      border: 1px solid var(--c-light);

      .jw-svg-icon.jw-svg-icon-play,
      .jw-svg-icon.jw-svg-icon-pause,
      .jw-svg-icon.jw-svg-icon-buffer,
      .jw-svg-icon.jw-svg-icon-replay {
        width: 26px;
        height: 26px;
        line-height: 26px;
      }

      .jw-svg-icon.jw-svg-icon-play path {
        transform: translateX(1px);
      }
    }

    /*
     * Hide button in the middle of the video frame
     * in all player states except buffering state
     */
    .jwplayer.jw-state-idle,
    .jwplayer.jw-state-playing,
    .jwplayer.jw-state-paused,
    .jwplayer.jw-state-complete {
      .jw-icon.jw-icon-display.jw-reset[role='button'] {
        display: none;
      }
    }

    .duration {
      position: absolute;
      top: 16px;
      left: 16px;
      display: flex;
      align-items: center;
      opacity: 0.8;
      background-color: var(--c-dark);
      padding: 0 12px;
      border-radius: 2px;
      height: 24px;
      gap: 4px;
      @include label-1(var(--c-light));

      &::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 12px;
        background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/audio/play-rounded.svg');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .bottom-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      color: var(--c-light);

      &::after {
        content: '';
        position: absolute;
        height: 220px;
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 7, 36, 0) 0%,
          var(--c-dark) 100%
        );
        border-radius: 0;
        bottom: 0;
        pointer-events: none;
      }

      .text-wrapper {
        position: relative;
        z-index: 100;
        padding: 0 32px 0 12px;

        p {
          @include label-1(var(--c-light));
          margin: 0 0 8px;
        }

        h2 {
          @include title();
          margin-bottom: 32px;
        }
      }
    }
  }
}
