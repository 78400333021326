.hh-outer-container {
  background-color: #471a0a;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 99;
  img {
    height: 40px;
  }
  .hh-inner-container {
    padding: 14px 10px 10px 10px;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
  }

  .hh-subscribe-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 24px;
    background: #d77c11;
    border-radius: 100px;
    border: none;
    outline: none;
    font-family: 'Graphik';
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    color: white;
  }

  .hh-subscribe-button:hover {
    background: #fcfbe9;
    color: #471a0a;
  }

  .hh-subscribe-button:active {
    background: #471a0a;
    color: white;
  }

  .hh-text-links {
    display: flex;
    width: 100%;
    align-items: center;
    font-family: 'Graphik';
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    width: 100%;
  }

  .hh-text-links > a {
    color: #fcfbe9;
    margin-left: 2em;
  }

  .content-outer {
    background-color: #fcfbe9 !important;
  }

  .hh-text-links > a {
    color: #fcfbe9;
  }

  h2.article-title {
    font-family: 'GraphikXCond' !important;
    font-weight: 600 !important;
  }

  @media (max-width: 767px) {
    .hh-text-links {
      justify-content: baseline;
    }
    .content-outer {
      background-color: unset;
    }

    .historie-section-sokbart-arkiv,
    .historie-section-gi-i-gave {
      display: none;
    }
  }
}
