.benefits-widget-container {
  display: flex;
  justify-content: flex-start;
  gap: 49px;
  position: relative;

  > * {
    flex: 0 0 209px;
    position: relative;
  }

  > *:not(:first-child)::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background: rgba(0, 7, 36, 0.1);
    flex-shrink: 0;
    left: -24.5px;
  }

  .benefits-teaser {
    margin: 16px 0;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 16px;

    &:focus {
      outline: auto;
      outline-color: blue;
    }

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .benefits-teaser-img-container {
        background-color: rgb(228, 228, 236);
        width: 209px;
        height: 209px;
      }

      .benefits-teaser-img {
        border-radius: 4px;
        object-position: center;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .benefits-teaser-tag,
      .benefits-teaser-title,
      .benefits-teaser-promotekst,
      .benefits-teaser-button-text {
        line-height: 16px;
        color: var(--c-text-link);
      }

      .benefits-teaser-tag {
        margin: 8px 0 4px 0;
        font-size: 12px;
        font-weight: bold;
      }

      .benefits-teaser-title {
        font-family: $fontPublicoHeadline;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: -0.3px;
        color: var(--c-dark);
      }

      .benefits-teaser-promotekst {
        margin: 8px 0 20px 0;
        font-size: 14px;
        font-weight: 400;
        color: #333;
      }

      .benefits-teaser-button {
        margin-top: auto;
        padding: 4px;
        border-radius: 2px;
        background-color: var(--c-blue-10);
        width: fit-content;
        .benefits-teaser-button-text {
          margin: 0 auto;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }
}
