@import '../../styles-desktop/widgets/carousel';

.carousel {
  margin-top: 0;

  .see-more-button {
    display: none !important;
  }

  .carousel-content {
    display: block;
    overflow-x: scroll;
    padding: 10px 12px;
    scroll-snap-type: x mandatory;
    scroll-padding: 0 0 0 12px;
    -webkit-overflow-scrolling: touch;

    &.more {
      margin-right: 0;
      border-right: none;
    }

    .content-item {
      display: inline-block;
      scroll-snap-align: start;
      scroll-snap-stop: always;
    }

    .content-item .content {
      display: block;
      width: 45vw;
      margin: 0 15px 0 5px;
      font-size: 12px;
      line-height: 1.28em;
    }

    &::after {
      content: '';
      flex: 0 0 12px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
