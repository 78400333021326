* {
  box-sizing: border-box;
}

html {
  height: auto;
}

body {
  font-family: $fontPublicoHeadlineRoman;
  color: #000c26;
  background: $colorBodyBackground;
  overflow-x: hidden;
  position: relative;

  &.with-wallpaper {
    background: #ffffff !important;
  }

  #inner-container {
    background-color: $colorBodyBackground;
  }
}

.content-outer {
  position: relative;
  margin-top: 4px; // for compatibility with js fasten code for skyscrapers stalking
}

#rootComponent {
  position: relative;
}

.mm-box2-container {
  &:not(:empty) {
    padding: $gridBase;
  }
}
