@keyframes feedback-animation {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
  }
  2% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

.copy-wrapper {
  position: relative;

  &-feedback {
    position: absolute;
    font-size: 12px;
    line-height: 1;
    overflow-wrap: break-word;
    color: #ffffff;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(0, 7, 36);
    box-shadow: 0px 8px 16px 1px rgb(0 0 0 / 8%),
      0px 3px 6px -3px rgb(0 0 0 / 20%);
    white-space: nowrap;
    margin: calc((40px - 12px - (8px * 2)) / 2) 0 0 4px;
    padding: 8px;
    animation: feedback-animation 4.9s ease both;

    &.position {
      &-left {
        top: -13px;
        left: -113px;
      }

      &-right {
        top: -13px;
        right: -113px;
      }

      &-top,
      &-bottom {
        right: 50%;
        transform: translateX(50%);
      }

      &-top {
        top: -45px;
      }

      &-bottom {
        bottom: -45px;
      }
    }
  }
}
