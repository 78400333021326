$tabletBreakpoints: (
  xlarge: (
    bp: 1009px,
    scaler: 0.91,
    width: 1090px,
  ),
  large: (
    bp: 833px,
    scaler: 0.8,
    width: 1035px,
  ),
  medium: (
    bp: 784px,
    scaler: 0.7604,
  ),
  small: (
    bp: 768px,
    scaler: 0.75,
    width: 1025px,
  ),
  xsmall: (
    bp: 600px,
    scaler: 0.73,
    width: 1010px,
  ),
);

@each $value, $key in $tabletBreakpoints {
  @media screen and (max-width: map-get($key, bp)) {
    #container {
      -webkit-transform-origin: 0 0;
      -webkit-transform: scale(map-get($key, scaler));
      transform-origin: 0 0;
      transform: scale(map-get($key, scaler));
      overflow-x: hidden;
      width: #{map-get($key, width)};
    }
  }
}
